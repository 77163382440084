﻿.partial.timeline-wrapper {
    .timeline-body {

        .book-now {
            margin: 20px 0 30px 0;
            min-width: 50%;

            .btn {
                color: white;
                font-size: large;
                width: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: 50%;
        }
    }

    .timeline-list-wrapper {
        padding: 20px 25px 0px 0px;

        .timeline-list {
            list-style-type: none;

            li {
                margin-bottom: 20px;
            }

            .item-container {
                min-height: 100px;
            }

            .path-line {
                background-color: #A5A5A5;
                width: 2px;
                height: 100%;
                margin-top: 10px
            }

            .blue-dot {
                width: 40px;
                text-align: center;

                .icon {
                    color: #0064F0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: 30%;
            margin-left: 50px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }
    }
}
