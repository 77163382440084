@import "../_variables";
@import "../_macros";

.macro-three-image-text{
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;

	h1{
		img{
			@media (min-width: 1200px) {
				display: block;
			}
			display:none;
		}
	}

	color: white;
	background-color: $brandNavy;
	
	&.theme-alt-blue{
		background-color: $brandBlueDarkAlt; //#024178
	}

	&.theme-white-left{
		color: $brandNavy;
		background-color: $brandLightGrey;
		.col {
			text-align: left;
		}
	}

	.col{
		text-align:center;


		img{
			margin-bottom: 1em;
		}

		.content{
			font-size: 15px;
			max-width: 19em;
			display:inline-block;
		}
	}

	.title-container{
		font-size: 24px;
	}

	a.btn{
		display: inline-block;
		background-color: $brandOrange;
		color: white;
		padding: 5px 20px;
		font-size: 14px;
		border-radius: 0;
	}
}