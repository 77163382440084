// Style guide defined colours
$brandNavy: #003057;
$brandBlue: #0064F0;
$brandBlueDarker: #0059d6;
$brandBlueDarkAlt: #034b88;
$brandBlueDarkest: $brandNavy;
$brandBlueOnBlueAccessable: #5a9dfb; // TODO use
$brandOrange: #FF3506;
$illustrationBlue: #1F28CF;
$illustrationGrey20: #D1D3D4;
$illustrationGrey10: #E6E7E8;
$brandLightGrey: #e9e9e9;
$brandBoxGrey: #f5f5f5;

$trafficLightScheduled: #17cf4b;
$trafficLightConfirmed: #ffbf00;
$trafficLightFewSeats: #fd7300;
$trafficLightSoldOut: #810081;

$backgroundBlack: #221f1f;
$lightCream: #f2eded;

// Override any bootstrap variables here
$orange:  #f28612;
$orangeContrast: #9c5407;
$primary:        $brandBlue !default;
$secondary:     $brandBlueDarkest !default;

$font-family-sans-serif: "Interstate", Arial;

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/


$fter_bgr:#003057;
$fter_txt:#fff;
$fter_trms: #222222;
$footer-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$footer-colors: map-merge(
  (
    "fter_bgr":    $fter_bgr,
    "fter_txt":  $fter_txt,
    "fter_trms":    $fter_trms
  ),
  $footer-colors
);

// Add new grid breakpoint
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1900px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1610px
);


// See https://getbootstrap.com/docs/4.3/getting-started/theming/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
