$container-side-margin: (
  xs: 10px,
  sm: 20px,
  md: 20px,
  lg: 20px,
  xl: 50px,
  xxl: 200px
) !default;


@mixin make-default-widget-spacing($spacings: $container-side-margin, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $spacing in $spacings {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			padding-left: $spacing !important;
			padding-right: $spacing !important;
		}
	}
}
