﻿.nav-city-list-partial {
    .office {
        width: 250px;
        margin: 25px 30px 25px 30px;

        .details {
            .title {
                width: 150px;
                font-size: 20px;
                font-weight: 500;
                color: #003057;
            }

            .link {
                width: 100px;
                height: 30px;
                font-size: 13px;
                border-radius: 5px;
                background-color: white;
                border-color: white;
                text-align: center;
                -webkit-box-shadow: -1px 1px 2px 3px #ccc;
                box-shadow: -1px 1px 2px 3px #ccc;

                &:hover {
                    -webkit-box-shadow: -1px 1px 2px 3px #003057;
                    box-shadow: -1px 1px 2px 3px #003057;
                }

                a {
                    color: #003057;
                    text-decoration: none;
                }
            }
        }

        .address {
            clear: both;
            font-size: 12px;
        }
    }
}
