﻿.shared-partial.checkboxlist-panels-wrapper .panel-item {
    width: 250px;

    &::before {
        text-align: center;
        transform: rotate(-90deg);
        position: absolute;
        margin-left: -115px;
        margin-top: 100px;
        min-width: 180px;
        color: white;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        font-size: larger;
        background-color: var(--label-color);
        content: var(--label-text);
    }

    .details-wrapper {
        border-radius: 20px;
        min-height: 250px;
    }
}
