.macro-course-structure {

	.download {
		font-size: 12px;
		a {
			color: $orangeContrast;
		}
	}

	.view-course {
		font-size: 11px;
		background-color: $brandBlue;
		border-radius: 20px;
		padding: 5px 10px;
		color: white;
		margin-right: 1em;
		min-width: 85px;
		text-align: center;

		@at-root body.isIE11 & {
			text-align: center;
		}

		@media (max-width: 762px) {
			border-radius: 0px;
			font-size: 16px;
		}
	}

	ul {
		list-style: none;
		margin-left: 1em;
		margin-top: 0.5em;
		padding: 0;
	}
	li {
		overflow: hidden;
		position: relative;
		padding: 0 0 23px 62px;

		@media (max-width: 762px) {
			padding: 0 0 13px 62px;
		}

		.row {
			min-height: 5em;
		}
	}

	li:first-child::before {
		top: 24px;
	}

	li::before {
		content: '';
		position: absolute;
		left: 19px;
		top: 0px;
		width: 20px;
		height: 999px;
		border: 2px solid #e2e2e2;
		border-width: 0px 0 0 9px;

		@media (max-width: 762px) {
			border: 0px;
		}
	}
	li:last-child::before {
		top: auto;
		bottom: 40px;
		border-width: 0px 0px 0px 9px;
	}
	li::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0px;
		width: 48px;
		height: 48px;
		background-color: #e2e2e2;
		border: 2px solid #e2e2e2;
		border-radius: 50%;
		background-size: 24px 24px;
		background-repeat: no-repeat;
		background-position: center;

		@media (max-width: 762px) {
			background-color: none;
			border: 0px;
			background-size: 48px 48px;
			border-radius: 0%;
		}
	}
	li.selected {
		color: #000;
		font-weight: 500;
	}
	li.selected::before {
		border-top-color: $brandNavy;
	}
	li.selected::after {
		border-color: $brandNavy;
		//background-color: $brandNavy;
	}

	@media (max-width: 762px) {
		.courses {
			display: none;
		}

		.row {
			.col:first-child {
				padding-top: 5px;
				-webkit-box-flex: 0;
				-ms-flex: 0 0 66.66667%;
				flex: 0 0 66.66667%;
				max-width: 66.66667%;
			}

			.col:nth-of-type(2) {
				-webkit-box-flex: 0;
				margin-top: -13px;
				-ms-flex: 0 0 33.33333%;
				flex: 0 0 33.33333%;
				max-width: 33.33333%;
			}
		}
	}
}
