﻿@import '../_variables';

.customise-training {

    padding: 50px;

    a.btn {
        width: 250px;
    }

    img {
        max-width: 100%;
    }

    h1.heading {
        color: #0064F0;
        font-weight: 100;
        font-size: 36px;
    }

    @include media-breakpoint-up(lg) {

        padding-left: 100px;
        
        .image, .content {
            width: 50%;
        }
    }
}
