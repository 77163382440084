﻿@import '../../_variables';
main.certification-path-root {
	section {
		&:nth-child(2n+2) {
			background-color: #F9F9F9;
		}

		padding: 20px 20px 20px 20px;

		@include media-breakpoint-up(lg) {
			padding: 60px 140px 60px 140px;
		}
	}

	.certification-list-wrapper {
		.certification-items {
			width: 150px;
			padding: 20px;
			min-height: 200px;
			margin: 30px;

			.cert-icon {
				min-height: 60px;
				border-radius: 50%;
			}

			a.btn {
				min-width: 100px;
			}
		}
	}

	.stats-wrapper {
		.donuts-wrapper {
			max-width: 800px;

			.stats-item {
				width: 150px;

				.donut-wrapper {
					width: 140px;

					h3 {
						position: absolute;
						line-height: 140px;
						margin-left: 40px;
					}
				}
			}
		}
	}

	.certification-video-wrapper {
		iframe {
			width: 700px;
			height: 400px;

			@include media-breakpoint-down(sm) {
				width: 300px;
				height: 150px;
			}
		}
	}
}
