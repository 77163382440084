﻿.partial.faq-wrapper {
    .faq-body {
        max-width: 900px;
    }

    .faq-item {
        max-width: 300px;
        margin: 30px;

        .icon {
            color: #0064F0;
        }

        .description {
            margin: 20px 0px 0px 35px;
        }
    }
}
