﻿.certification-partials {
	&.perks-wrapper {
		padding: 60px 85px 60px 85px;

		.perk-item {
			width: 200px;
			margin: 30px;
			text-align: center;

			img {
				max-width: 70%;
			}
		}

		.perk-link {
			min-width: 20px;
			text-align: center;
			margin-top: 100px;
		}
	}
}
