@import './../../variables';

.blog-search {
    width: 750px;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.blog-tag.main-wrapper {
    .list-wrapper {
        .tag {
            margin: 10px;
        }

        a {
            background-color: #D1D3D4;
            color: black;
            padding: 5px;
        }
    }
}

.blog-pagination {
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 16px;
    display: flex;
    justify-content: center;

    .nav-bar {
        display: flex;
        gap: .5rem;

        * {
            display: inline-block;
        }
    }

    .nav-link {
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    .nav-link:hover {
        color: $white;
        background-color: $brandBlue;
    }

    .icon {
        margin-top: 4px;
    }

    .nav-link--disabled, .nav-link--disabled:hover {
        background-color: transparent;
        color: gray;
    }

    .nav-link--active {
        color: $white;
        background-color: $brandNavy;
    }
}