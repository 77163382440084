﻿@import '../_variables';
.partial-view.title-text {

    .title-button {
        max-width: 200px;
    }

    .title-image img {
        max-width: 100%;
        object-fit: contain;
    }

    &.dark {
        color: white !important;
        background-color: #003057;

        h1 {
            color: white !important;
        }

        div .accordian.accordian-p div.accordian-item > *:first-child {
            background-color: inherit;
            border-bottom: 1px solid white;
        }

        &.desktop-view .description a {
            color: #8fbeff;
        }

        &.mobile-view .sub-heading a {
            color: #8fbeff;
        }
    }

    &.desktop-view {
        h1 {
            color: #0064F0;
            font-weight: 500;
            font-size: 36px;
        }

        h2, h4 {
            font-size: 22px;
            font-weight: 500;
        }

        .description {
            a {
                color: #0064F0;
            }
        }
    }

    &.mobile-view {
        h1.title {
            font-size: 35px;

            p {
                margin-bottom: 0;
            }
        }

        .title-review {
            .stars {
                padding-bottom: 4px;

                i {
                    color: #fda80b;
                }
            }

            .link a {
                color: white;
            }
        }
    }
}
