@import '../_variables';
@import '../_macros';

.macro-offices {
	@media (max-width: 762px) {
		display: none;
	}
	display: flex;
	background-color: #e5e5e5;
	color: $brandNavy;
	.microsoftcourses {
		color: #084394;
	}
	a {
		color: $brandNavy;
	}

	img {
		margin-right: 1em;
	}

	@include media-breakpoint-between(xs, md) {
		img {
			display: none;
		}
	}

	ul li {
		position: relative;
		text-align: left;
		width: 150px;
		align-items: flex-start;

		& > .icon {
			position: absolute;
			left: -22px;
			color: $brandOrange;
		}

		.title,
		& > .icon {
			font-size: 1.4em;
		}

		@at-root body.isIE11 & {
			display: block !important;
		}
	}
}
