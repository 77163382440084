﻿.partial-view.grid-item {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 300px;
    background: white;
    font-weight: normal;
    font-size: 14px;

    .blog-image {
        width: 300px;

        img {
            max-width: 300px;
        }
    }

    .blog-body {
        padding: 30px;

        .blog-title a {
            color: black;
        }
    }

    .blog-footer {
        background: #F9F9F9;
    }
}

.badge {
    background-color: #FF3506;
}
