@import '../_variables';
@import '../_macros';

.course-blue-box.rating {
	.parking-icon {
		background-color: transparent;
	}
}

.course-blue-box {
	background-color: $brandBoxGrey;
	margin-bottom: 1em;
	font-size: 0.8em;
	width: 100%;

	.columns-2 {
		columns: 2;
	}

	h2 {
		font-size: 1em !important;
		font-weight: 400 !important;
		padding: 0;
		margin: 0;
		line-height: inherit;
	}

	iframe {
		flex: 1;
		width: 100%;
	}

	&.large {
		flex: 1;
		min-height: 380px;
		//height: 100%;
		@include media-breakpoint-down(lg) {
			min-height: 0px;
			height: 100%;
		}

		display: flex;
		flex-direction: column;
	}

	&.small {
		min-height: 190px;

		@include media-breakpoint-down(lg) {
			min-height: 0px;
		}
	}

	& > .title,
	& > .body > .title {
		background-color: $brandNavy;
		padding: 0.6em;
		cursor: pointer;
		color: white;
		margin-bottom: 1em;

		@media (max-width: 762px) {
			color: $brandNavy;
			font-size: 18px !important;
			font-weight: 500;
			background-color: white;
		}

		&.accordion {
			@media (max-width: 762px) {
				background-color: white;
				color: $brandNavy;
				-webkit-box-shadow: -2px 2px 5px 0px #95b8d4;
				box-shadow: -2px 2px 5px 0px #95b8d4;
				border-radius: 5px;

				h2 {
					font-size: 16px !important;
					font-weight: 400;
					padding-top: 4px;
				}

				font-size: 20px !important;
				font-weight: 500;
			}
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 0px;
		}

		&.no-pad {
			margin-bottom: 0;
		}

		p {
			margin-bottom: 0px;
		}
	}

	.bluebox-row {
		color: $brandNavy;
		padding: 3px 20px;
		display: flex;
		justify-content: space-between;

		div: {
			display: inline-block;
		}

		& > .address {
			text-align: right;
			max-width: 120px;
		}
	}

	.prophidden {
		display: none;
	}

	.stars {
		color: #ffb900 !important;
	}

	.starstittle {
		color: #ffb900 !important;
		float: right;
	}

	.contact-form {
		margin: 1em;

		@media (max-width: 762px) {
			margin: 0px;

			&.active {
				margin: 1em;
			}
		}
	}

	.parking-icon {
		display: inline-block;
		background-color: $brandBlue;
		border-radius: 100%;
		width: 80px;
		height: 80px;
		margin: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 52px;
		font-weight: bold;
	}

	.content-with-icon {
		display: flex;

		& > *:first-child {
			//flex: 1;
		}

		& > *:last-child {
			flex: 1;
			//display: flex;
			//align-items: center;
		}
	}

	.city-links {
		color: $orangeContrast;
		margin: 1em;
		columns: 1;

		@media (max-width: 762px) {
			margin: 0px;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}

		a {
			color: $orangeContrast;

			&:visited {
				color: $orangeContrast;
			}
		}
	}

	.city-links-courses {
		color: $orangeContrast;
		margin: 1em;
		columns: 1;

		@media (max-width: 762px) {
			margin: 0px;
			min-height: auto;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}

		a {
			color: $orangeContrast;

			&:visited {
				color: $orangeContrast;
			}
		}
	}

	.other-courses {
		margin-top: 1em;

		@media (max-width: 762px) {
			margin-top: 0px;
			padding-top: 0px;
			max-height: 401px !important;
		}

		ul {
			list-style-type: none;
			padding: 0em 0px;
			padding-left: 10px;

			li {
				display: inline-block;
				margin-left: 0em;
				margin-bottom: 1em;
				padding-right: 10px;

				img {
				}
			}
		}
	}

	.course-content {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		//grid-auto-rows: 20px;
		grid-gap: 5px;

		& > p {
			display: none;
		}
		// IE 11 reset back to columns
		@at-root body.isIE11 & {
			display: block;
			columns: 5;

			@include media-breakpoint-down(md) {
				columns: 3;
			}

			@include media-breakpoint-down(sm) {
				columns: 1;
			}
		}

		ul {
			margin-left: 1.5em;

			li {
                padding-left: 12px;
				//list-style: square inside url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
			}

			li:first-child {
				list-style: none;
				font-size: 14px;
				margin-left: -1em;
				position: relative;
                padding-left: 24px;

				&:before {
					position: absolute;
					left: 0;
					content: ' ';
					display: block;
					background-size: 100% auto;
					height: (128px) / 6;
					width: (128px) / 6;
				}
			}

			@media (max-width: 762px) {
				margin-left: 0px;
				font-size: 15px;

				li:not(:first-child) {
					display: none;
				}

				li:first-child::after {
					content: '+';
					position: absolute;
					z-index: 1;
					padding-left: 20px;
					font-size: 15px;
					font-weight: 500;
				}

				li.active::after {
					content: '-';
					position: absolute;
					z-index: 1;
					padding-left: 20px;
					font-size: 15px;
					font-weight: 500;
				}

				li:first-child:before {
					display: none;
				}
			}

			font-size: 11px;
		}
	}

	h1,
	h2,
	h3,
	h4 {
		font-size: 1.2em;
	}
}

.course-blue-box-mobile {
	.bluebox-row-mobile {
		width: 33.33%;
		float: left;
		font-size: 14px;

		.icon-font-clock {
			color: $brandNavy;
		}

		.icon-font-price-tags {
			padding-top: 3px;
			font-size: 14px;
			color: $brandNavy;
		}

		.icon-font-users {
			color: $brandNavy;
		}

		div {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}

	padding-bottom: 20px;
}

.pricesmobile {
	background-color: #d1def1;
	display: none;
	padding-top: 10px;
	padding-bottom: 12px;
	margin-bottom: 10px;
	-webkit-box-shadow: 0px 2px 3px 0px grey;
	box-shadow: 0px 2px 3px 0px grey;

	@include media-breakpoint-down(md) {
		display: block;
	}
}

.courses-slider {
	//@include make-default-widget-spacing();
	background-color: white;
	.items {
		padding-top: 25px;

		@media (max-width: 762px) {
			padding-top: 0px;
		}
		.item {
			//width: 25%;
		}

		.slick-arrow {
			color: #f5f5f5;
			background-color: #636060;
			border-radius: 100%;
		}

		margin: 1em;
		.item-wrap {
			text-align: center;
		}

		.item {
			display: flex;
			flex-direction: column;

			text-align: center;
			width: 70px;

			min-height: 70px;
		}
	}
}

.courses-slider.desktop {
	overflow: auto;
	overflow-x: hidden;
	overflow-y: hidden;

	.item {
		width: 70px;
		height: 70px;
		float: left;
	}

	@media (max-width: 762px) {
		display: none;
	}
}

.courses-slider.mobile {
	display: none;

	@media (max-width: 762px) {
		display: block;
	}

	.glider-contain {
		.glider-slide {
            min-width: 0;
        }
        .glider-prev, .glider-next {
            top: 25px;
            font-size: 25px;
        }
        .glider-prev {
            padding-left: 7px;
        }
        .glider-next {
            padding-right: 7px;
			right: -23px;
        }
	}
}

.city-slider {
	//@include make-default-widget-spacing();
	background-color: white;
	@media (min-width: 414px) and (max-width: 762px) {
		margin-left: 10px;
	}

	.items {
		display: block;
		padding-top: 25px;

		@media (max-width: 762px) {
			padding-top: 0px;
		}

		.item {
			margin: 0 5px 10px;

			@media (max-width: 762px) {
				margin: 0 1px 10px;
			}
			float: left;
		}

		.slick-arrow {
			color: #f5f5f5;
			background-color: #636060;
			border-radius: 100%;
		}

		.item-wrap {
			text-align: center;
		}

		.item {
			text-align: center;
			width: 80px;
			min-height: 80px;

			@media (min-width: 414px) and (max-width: 762px) {
				width: 80px;
				min-height: 80px;
				margin-right: 5px;
			}
		}
	}
}

@media (max-width: 762px) {
	.noshowmobile {
		display: none;
	}
	.mobile {
		padding-top: 15px;
	}
	.mobiletittle {
		display: none;
	}

	.cityabbr {
		width: 71px;
		height: 71px;
		float: left;
		-webkit-box-shadow: -2px 2px 5px 0px #95b8d4;
		box-shadow: -2px 2px 5px 0px #95b8d4;
		border: none;
		border-radius: 10px;

		p {
			font-size: 25px;
			font-weight: 800;
			text-align: center;
			line-height: 12px;
			padding-top: 26px;
			color: $brandNavy;

			span {
				font-size: 11px;
			}
		}
	}

	.city-slider {
		.title {
			padding-left: 3px;
		}
		.city-links {
			.slick-list {
				height: 100px;
			}

			.cityabbr {
				width: 80px;
				min-height: 80px;
			}
		}
	}
}
