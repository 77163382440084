@import '../_variables';
@import '../_macros';

.awards.macro-automatic-icon-list {
	display: none;
}

.cattittle {
	@media (max-width: 762px) {
		display: none;
	}
}

.cattittle.workplace {
	@media (max-width: 762px) {
		display: block;
		p {
			font-size: 20px;
		}
	}
}

.macro-automatic-icon-list.template {
	.fixed-icon {
		width: 81px;
		height: 81px;
	}

	@media (max-width: 762px) {
		a {
			color: $brandNavy;
			font-size: 16px;
		}
	}
}

@media (max-width: 762px) {
	.macro-automatic-icon-list.template {
		background-color: #e5ebee;
	}
	.macro-trusted-by-business.macro-automatic-icon-list.theme-white {
		background-color: white;
		.heading {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.awards-home {
		display: none;
	}
	.macro-automatic-icon-list.macro-why-nexacu {
		display: none;
	}

	.awards.macro-automatic-icon-list.theme-white {
		display: block;
		background-color: white;
		.heading {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.macro-automatic-icon-list.theme-white-real {
		//display: none;
	}
}

.macro-automatic-icon-list {
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;

	color: white;
	background-color: $brandBlueDarkest;

	&.theme-white {
		background-color: $lightCream;
		color: $brandNavy;

		a {
			color: $brandNavy;

			&:hover {
				color: $brandNavy !important;
			}
		}
	}

	&.theme-white-white {
		background-color: #fff;
		color: $brandNavy;
		padding-top: 0px;
		padding-bottom: 2 em;
		text-align: center;

		a {
			color: $brandNavy;

			&:hover {
				color: $brandNavy !important;
			}
		}
		ul {
			margin: 0 auto;
			text-align: center;
			display: inline-flex;
			li {
				float: left;
				max-width: 500px;
				margin: 0px 4em;

				@media (max-width: 1420px) {
					margin: 0px 3em;
				}

				@media (max-width: 1200px) {
					margin: 0px 2.5em;
				}

				@media (max-width: 1000px) {
					margin: 0px 1em;
				}

				@media (max-width: 550px) {
					margin: 0px 3em 2em 4em;
				}
			}
		}
	}

	&.theme-white-real {
		background-color: lighten($lightCream, 2%);
	}

	&.theme-light-blue {
		background-color: $brandBlue;
	}

	&.macro-trusted-by-business {
		@include media-breakpoint-down(sm) {
			ul {
				display: flex;
				flex-wrap: wrap;
			}

			li {
				display: none; // TODO MAKE SLIDESHOW

				&:nth-of-type(-n + 6) {
					display: block;
				}
			}
		}
	}

	h1 {
		font-weight: 100;
	}

	a {
		color: white;

		&:hover {
			color: white;
		}
	}

	ul {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 12px;

		li {
			display: flex;
			flex-direction: column;
			align-items: center;

			margin: 2em 1em;

			max-width: 300px;

			text-align: center;

			img {
				margin: auto;
				//margin-bottom: 2em;
			}

			h3 {
				// Undo h3 styles
				font-size: inherit;
				margin-bottom: 0;
				font-weight: inherit;
				line-height: inherit;
			}
		}
	}
	@media (min-width: 414px) and (max-width: 762px) {
		ul.listmobile {
			li {
				max-width: 100px !important;
			}
		}
	}
}
