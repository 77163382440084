@import '../_variables';
@import '../_macros';

.macro-workplace-booking-course-outline {
	background-color: #e5ebee;
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;

	color: $brandNavy;

	@include media-breakpoint-down(xs) {
		text-align: center;
	}

	@media (max-width: 762px) {
		h1 {
			font-size: 24px;
		}
	}

	.learntouse {
		display: none;
	}

	.items {
		text-align: center;
	}

	.item {
		display: inline-block;
		text-align: center;
		margin: 1em 3em;
		width: 130px;

		@include media-breakpoint-down(sm) {
			display: inline-block;
			width: 80px;
			margin: 1em 1em;
			font-size: 14px;
			.learntouse {
				display: block;
			}
		}

		.icon {
			margin: 10px 0em;

			@media (max-width: 762px) {
				width: 80px;
				img {
					width: 64px;
				}
			}
		}

		.title {
			font-weight: 500;
		}

		.download {
			display: block;
			@media (max-width: 762px) {
				display: none;
			}
			background-color: $brandOrange;
			color: white;
			padding: 5px 10px;
			font-size: 14px;
		}
	}
}

.theme-normal.certificationPath.first.noshow {

	@media (max-width: 762px){
		display:none;
	}
}

.theme-normal.certificationPath.first.noshow.first.blue {

	@media (max-width: 762px){
		display:block !important;

		.stars{
			display:none;
		}
		.link{
			display:none;
		}

		.button-container{
			display:none;
		}
	}
}
	


