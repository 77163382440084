@import '../_variables';
@import '../_macros';

.macro-review-slider {
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;
	color: white;
	background-image: url('../core/images/review-pattern.png');

	@media (max-width: 762px) {
		.inner {
			display: none;
		}

		.text-center {
			display: none;
		}

		background-image: none;
		background-color: #003057;
	}

	&.multiline {
		.items {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@include media-breakpoint-between(md, xxl) {
				.item-wrap {
					flex: 1;
					display: flex;
				}
			}

			@include media-breakpoint-between(xs, sm) {
				display: block;

				.item {
					width: 100%;
				}
			}
		}

		.pagination {
			display: flex;
			justify-content: space-between;
			margin-top: 2em;

			.disabled {
				pointer-events: none;
			}
		}
	}

	&.slider {
	}

	.inner {
		position: relative;
	}

	.header {
		@include media-breakpoint-between(sm, xxl) {
			display: flex;
			justify-content: center;
		}

		@include media-breakpoint-between(sm, lg) {
			justify-content: space-between;
		}

		h3 {
			font-weight: 100;

			@include media-breakpoint-between(xl, xxl) {
				position: absolute;
				left: 0px;
			}
		}

		.header-rating-container {
			& > div:first-child {
				font-size: 3em;
				color: $brandOrange;
			}
		}
	}

	.glider-contain {
		button.glider-prev {
			top: 100px;
			padding-left: 12px;
		}

		button.glider-next {
			top: 100px;
			padding-right: 6px;
			right: -15px;

			@include media-breakpoint-down(sm) {
				right: -10px;
			}
		}
	}

	.items {
		margin: 1em;

		.item-wrap {
			text-align: center;
		}

		.item {
			display: flex;
			flex-direction: column;
			background-color: white;
			color: $brandNavy;
			margin: auto;
			padding: 2em;
			text-align: center;
			width: 300px;
			font-size: 0.8em;
			min-height: 200px;

			@include media-breakpoint-down(sm) {
				width: 200px;
			}

			@include media-breakpoint-only(md) {
				width: 322px;
			}

			a {
				color: $brandNavy;
			}

			.stars {
				font-size: 1.5em;
				margin-bottom: 0.5em;
			}

			.description {
				flex: 1 1 auto;
				margin: 0.4em 0;
			}

			.who {
				font-weight: bold;
			}
		}
	}
}
