@import '../_variables';
@import '../_macros';

.macro-checklist-success {
	background-color: $brandNavy;
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;

	color: white;

	@include media-breakpoint-down(xs) {
		text-align: center;
	}
	h1 {
		font-size: 36px;
		font-weight: 100;
	}

	ul {
		list-style: none;
		display: inline-block;
		max-width: 30%;
		margin: 2em 2em;
		float: left;
		height: 145px;

		@include media-breakpoint-down(sm) {
			width: 80%;
			max-width: 80%;
			display: block;
		}

		li:first-child {
			font-size: 23px;
			margin-bottom: 0.4em;
			position: relative;

			&:after {
				display: block;
				content: ' ';
				height: 30px;
				width: 30px;
				background-image: url('../core/images/checkbox.png');
				background-size: 30px 30px;
				position: absolute;
				left: -60px;
				top: 8px;
				float: left;
			}
		}

		li {
			font-size: 13px;
		}
	}
}
@media (max-width: 762px) {
	.macro-checklist-success.theme-workplace {
		display: none;
	}

	.workplace.extracontent {
		display: none;
	}
}

.button-container {
}
