@import '../_variables';

.contact-form-wrapper {
	.main-div {
		width: 450px;

		.form-group {
			&.first-name, &.city {
				margin-right: 10px;
			}
		}
	}

	.spinner {
		margin: 0;
		font-size: 5px;
		display: none;
	}

	@include media-breakpoint-down(lg) {
		.main-div {
			padding: 30px;

			.form-group {
				margin-right: 0;

				&.first-name, &.city {
					margin-right: 0;
				}
			}
		}
	}
}
