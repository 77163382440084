@import "../_variables";
@import "../_macros";

.macro-quote{
	background-color: $backgroundBlack;
	color: white;

	@include make-default-widget-spacing();

	padding-top: 2em;
	padding-bottom: 2em;

	font-size: 40px; 

	text-align: center;
}

.macro-quote.certification{


	padding-top: 1.5em;
	padding-bottom: 1.5em;

	font-size: 30px; 

}