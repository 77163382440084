.top-blog-wrapper {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgb(255, 255, 255) 50%, rgb(242, 242, 242) 50%);
    font-size: 14px;

    .blog-title a {
        color: black;
    }

    .blog-footer {
        background: white;
    }

    .inner-wrapper {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

        .blog-body {
            width: 500px;
            background: white;
            padding: 20px;
        }
    }
}

.blog-list-title {
    text-align: center;
}

.blog-grid {
    background-color: #f2f2f2;
}