@import '../_variables';
@import '../_macros';

.macro-why-nexacu {
	background-color: #FFFFFF;
	color: $brandNavy;

	a {
		color: $brandNavy;

		&:hover {
			color: $brandNavy;
		}
	}

	ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include media-breakpoint-down(md) {
			justify-content: center;
		}

		li {
			display: flex;
			flex-direction: column;
			align-items: center;

			img {
				margin: auto;
			}
		}
	}
}