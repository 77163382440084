﻿@import '../../_variables';
main.certification-path {

    .comparisons-raw-image {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    section {
        &:nth-child(2n+2) {
            background-color: #F9F9F9;
        }

        padding: 20px 20px 20px 20px;

        @include media-breakpoint-up(lg) {
            padding: 60px 140px 60px 140px;
        }
    }

    .certification-details-wrapper {
        text-align: center;

        .detail-items {

            @include media-breakpoint-up(xl) {
                min-width: 1200px;
            }

            .vertical-line {
                border-left: 2px solid #A5A5A5;
                width: 5px;
                height: 50px;

                @include media-breakpoint-between(xs, sm) {
                    display: none;
                }
            }

            .detail-item {
                margin: 1em 1em;

                .content {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                .title {
                    font-weight: 500;
                    margin-bottom: 20px;
                }

                @include media-breakpoint-up(sm) {
                    max-width: 140px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
        }
    }

    .key-features-wrapper {
        .key-feature-item {
            margin: 30px;

            .key-feature-img {
                width: 500px;
                height: 300px;
                margin: 30px;
                text-align: center;

                img {
                    max-width: 500px;
                    max-height: 300px;
                }

                @include media-breakpoint-down(sm) {
                    width: 300px;
                    height: 300px;

                    img {
                        max-width: 300px;
                        max-height: 300px;
                    }
                }
            }

            .key-feature-description {
                max-width: 700px;
                margin: 30px;
            }
        }
    }
}