/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.first.mobile {
  background-color: #003057;
}

.altertemplate {
  display: none;
}
@media (max-width: 762px) {
  .altertemplate {
    display: block;
  }
}

.first.blue {
  display: none;
  background-color: #003057;
  margin-top: -11.5px;
  padding-top: 10px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .first.blue {
    display: block;
  }
}
@media (min-width: 768px) {
  .first.blue {
    margin-top: -49.5px;
  }
}
.first.blue .row div {
  color: white;
}
.first.blue .row div .stars {
  float: left;
}
.first.blue .row div .stars .icon-font-star {
  color: #fda80b;
}
.first.blue .row div .link {
  float: left;
  padding-left: 10px;
  text-decoration: underline;
}
.first.blue .row div .link a {
  color: white;
}
.first.blue .row div h1 {
  text-align: left;
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}
.first.blue .row div p {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.image-text-macro.alter-template {
  background-color: #e5ebee;
}
.image-text-macro.alter-template .row div h1 {
  color: #003057;
}

.image-text-macro.certificationPath {
  background-color: #003057;
  margin-top: -2em;
}
@media (max-width: 767.98px) {
  .image-text-macro.certificationPath {
    margin-top: -11px;
  }
}
.image-text-macro.certificationPath .row div h1 {
  color: white;
}
.image-text-macro.certificationPath .row div .content {
  color: white;
}

.image-text-macro.alter-template.workplacecourse {
  background-color: #003057;
  color: white !important;
}
.image-text-macro.alter-template.workplacecourse .row div h1 {
  color: white;
}

.altertemplate {
  background-color: rgba(0, 48, 87, 0.6588235294);
  color: white;
  padding: 50px;
  font-size: 16px;
}

.altertemplate.workplacecourse {
  background-color: #003057;
  color: white;
}

.altertemplate.workplace {
  padding: 20px;
  background-color: #003057;
}
.altertemplate.workplace p {
  padding-top: 10px;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}

.image-text-macro {
  padding-left: 10px !important;
  padding-right: 10px !important;
  color: black;
  padding-bottom: 1em;
}
@media (min-width: 576px) {
  .image-text-macro {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .image-text-macro {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .image-text-macro {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .image-text-macro {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .image-text-macro {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
.image-text-macro h1.homemobiletitle {
  font-size: 35px !important;
  font-weight: 1000;
}
.image-text-macro h1,
.image-text-macro h2 {
  margin-bottom: 30px;
}
.image-text-macro h1 {
  margin-top: 1em;
  font-size: 36px;
  font-weight: 100;
  color: #0064F0;
}
.image-text-macro.first h1 {
  font-weight: 500;
}
.image-text-macro h2 {
  font-size: 22px;
  font-weight: 500;
}
.image-text-macro .row div:first-child {
  text-align: center;
}
@media (max-width: 991.98px) {
  .image-text-macro .row.mobile {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.image-text-macro .content {
  color: #003057;
}
.image-text-macro a.btn {
  background-color: #0064F0 !important;
  border-width: 0px;
  padding: 5px 40px;
}
.image-text-macro video {
  width: 100%;
}
.image-text-macro.is-empty div:first-child {
  text-align: left;
}
.image-text-macro.is-empty .image-container {
  text-align: center;
}
.image-text-macro.is-empty-media .row > div:last-child {
  text-align: center;
}
@media (max-width: 991.98px) {
  .image-text-macro .noshow {
    display: none;
  }
}
.image-text-macro.theme-title-page h1, .image-text-macro.theme-title-page-reverse h1 {
  color: #0064F0;
}
.image-text-macro.theme-title-page .content, .image-text-macro.theme-title-page-reverse .content {
  background-color: #f5f5f5;
  padding: 1em;
  color: #003057;
}
.image-text-macro.theme-title-page .button-container, .image-text-macro.theme-title-page-reverse .button-container {
  text-align: center;
}
.image-text-macro.theme-title-page .button-container .btn, .image-text-macro.theme-title-page-reverse .button-container .btn {
  margin-top: -24px;
  border-radius: 0px;
}
.image-text-macro.theme-mirror .row .col:first-child, .image-text-macro.theme-title-page-reverse .row .col:first-child {
  order: 2;
}
.image-text-macro.theme-mirror .row .col:last-child, .image-text-macro.theme-title-page-reverse .row .col:last-child {
  order: 1;
}

@media (max-width: 762px) {
  .theme-mirror.workplace .button-container {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .page-type-home .image-text-macro {
    padding-bottom: 0px;
  }
  .page-type-home .image-text-macro .row > div:first-child {
    display: block !important;
    min-width: 100%;
  }
  .page-type-home .image-text-macro h1 {
    margin: 0px;
  }
}

@media (max-width: 991.98px) {
  .page-type-contentPage .image-text-macro .row > div:first-child {
    display: block !important;
    min-width: 100%;
  }
  .page-type-contentPage .image-text-macro .row > div:first-child img {
    width: 100% !important;
  }
}

.mobileimage img {
  width: 100%;
}

.citypagemobile {
  display: none;
}

@media (max-width: 762px) {
  .course-blue-box.template .city-links.home {
    margin-left: 10px;
  }
}
.course-blue-box.template .city-links.home .item {
  margin: 0px 1px 5px 0px;
}
.course-blue-box.template .city-links.home .item a .cityabbr {
  width: 75px;
  height: 75px;
}
.course-blue-box.template .city-links.home .item a .cityabbr p {
  padding-top: 20px;
}

@media (min-width: 414px) and (max-width: 762px) {
  .city-links.items.home .item .cityabbr {
    height: 80px;
    width: 80px;
  }
}

@media (max-width: 762px) {
  .first.noshow {
    padding: 0px;
  }
  .first.blue {
    padding-top: 0px;
  }
  .row.mobile {
    padding-top: 5px;
  }
  .row.mobile p {
    margin: 0px;
  }
  .skills-test {
    display: none;
  }
  .rating {
    display: none;
  }
  .citypage {
    display: none;
  }
  .citypagemobile {
    display: block;
  }
  .contactus {
    display: none !important;
  }
  .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  .accordion.active::after {
    content: "-";
    position: absolute;
    z-index: 1;
    padding-left: 80%;
    font-size: 18px;
    margin-top: -30px;
  }
  .accordion::after {
    content: "+";
    position: absolute;
    z-index: 1;
    padding-left: 80%;
    font-size: 18px;
    margin-top: -30px;
  }
  .active.accordion + div {
    padding-top: 10px;
  }
  .panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .panel.active {
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 0px;
    overflow: hidden;
  }
  .panel.active.courseoutline {
    max-height: 100%;
  }
}
/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.async-partial-view.schedule {
  min-height: 500px;
}

.course-dates-macro {
  color: #003057;
  padding-bottom: 1em;
  min-height: 250px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
body.isIE11 .course-dates-macro {
  display: block;
}

.course-dates-macro .mobile-title {
  display: none;
}
.course-dates-macro .overlay {
  height: 250px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  backdrop-filter: blur(2px);
  margin: 0 14px 0 14px;
}
.course-dates-macro .overlay .selection {
  z-index: 3;
  width: 80%;
  height: 180px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  background-color: #f5f5f5;
  padding-top: 50px;
  padding-left: 6%;
  padding-right: 6%;
}
.course-dates-macro .overlay .selection p {
  margin-top: 10px;
}
@media (max-width: 1199.98px) {
  .course-dates-macro .overlay .selection .extra {
    display: none;
  }
}
.course-dates-macro .overlay .selection.book-now.region a {
  width: 150px;
  padding: 5px 5px;
  margin-left: 5px;
  border: 1px solid white;
}
@media (max-width: 1199.98px) {
  .course-dates-macro .overlay .selection.book-now.region a {
    width: 90px;
  }
}
.course-dates-macro .overlay .selection.book-now.region a img {
  width: 30px;
}
.course-dates-macro .overlay .selection ul.top {
  display: block;
  top: 0px;
  right: 2em;
}
.course-dates-macro .overlay .selection ul.top > li {
  min-width: initial;
}
.course-dates-macro .overlay .selection ul.top > li > a {
  color: white;
  min-width: 100px;
  text-align: center;
  line-height: initial;
  display: inline-block;
  padding: 5px 20px;
}
.course-dates-macro .overlay .selection ul.top > li .dropdown-container {
  flex-direction: column;
  justify-content: flex-start;
  min-height: initial;
  background-color: white;
  box-shadow: 3px 10px 17px -1px rgba(0, 0, 0, 0.75);
  text-transform: lowercase;
}
.course-dates-macro .overlay .selection ul.top > li .dropdown-container a {
  display: block;
  margin-bottom: 15px;
  color: white;
}
.course-dates-macro .overlay .selection ul.top > li .dropdown-container a:last-child {
  margin-bottom: 0px;
}
.course-dates-macro .overlay .selection > ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 5px;
}
.course-dates-macro .overlay .selection > ul > li {
  text-transform: lowercase;
  min-width: 110px;
  font-size: 18px;
  display: inline-block;
}
.course-dates-macro .overlay .selection > ul > li .expander-toggle {
  display: none;
}
.course-dates-macro .overlay .selection > ul > li .expander-cat {
  display: none;
}
.course-dates-macro .overlay .selection > ul > li.book-now a {
  width: 130px;
  display: inline-block;
  background-color: #0064F0;
  padding: 5px 30px;
  margin-left: 5px;
  border: 1px solid white;
}
.course-dates-macro .overlay .selection > ul > li.book-now a:hover {
  background-color: #005cdc;
}
.course-dates-macro .overlay .selection > ul > li.book-now.region a {
  min-width: 160px;
  padding: 5px 5px;
  margin-left: 5px;
  border: 1px solid white;
}
@media (max-width: 1199.98px) {
  .course-dates-macro .overlay .selection > ul > li.book-now.region a {
    width: 90px;
  }
}
.course-dates-macro .overlay .selection > ul > li.book-now.region a img {
  width: 30px;
}
.course-dates-macro .overlay .selection > ul > li.book-now.region {
  height: 44px;
}
.course-dates-macro .overlay .selection > ul > li.book-now.region a {
  min-width: 135px;
  width: 160px;
  padding: 5px 3px;
  height: 32px;
}
.course-dates-macro .overlay .selection > ul > li.book-now.region a span {
  padding-left: 5px;
  margin-right: 5px;
  float: right;
  font-weight: 500;
  transform: rotate(90deg);
}
.course-dates-macro .overlay .selection > ul > li.book-now.region .dropdown-container {
  padding-right: 8px;
  padding-left: 16px;
  background-color: white;
}
.course-dates-macro .overlay .selection > ul > li.book-now.region .dropdown-container a {
  background-color: white;
  margin-left: 0px;
  padding: 0px;
  color: black;
  text-transform: capitalize;
}
.course-dates-macro .overlay .selection > ul > li.book-now.region .dropdown-container a img {
  padding-right: 10px;
}
.course-dates-macro .overlay .selection > ul > li > a {
  color: white;
  padding: 0px 5px 5px 5px;
}
.course-dates-macro .overlay .selection > ul > li > a:hover {
  text-decoration: none;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container {
  text-transform: none;
  background-color: white;
  color: #003057;
  min-height: 10em;
  margin-top: 15px;
  width: 100%;
  display: none;
  z-index: 999;
  justify-content: center;
  box-shadow: 1px 10px 13px -7px rgba(0, 0, 0, 0.75);
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container.nosize {
  width: auto;
  padding: 1em;
  margin-top: 0;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container.book-now a {
  border-radius: 0;
  margin: 1em;
  width: 10em;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container.about-us {
  flex-direction: column;
  align-items: center;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container.about-us .top {
  display: flex;
  width: 100%;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container.about-us .bottom .btn {
  margin: 1em 2em;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container a {
  color: #003057;
}
.course-dates-macro .overlay .selection > ul > li .dropdown-container [x-arrow] {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  margin-left: 18px;
  top: -10px;
}
.course-dates-macro .overlay .selection ul li {
  padding: 0.5em 0;
}
.course-dates-macro .overlay .selection ul li .dropdown-container {
  display: none;
}
.course-dates-macro .overlay .selection ul li .dropdown-container a {
  border-bottom: none;
  font-weight: 400;
}
.course-dates-macro .overlay .selection ul li .book-now.region {
  height: 44px;
}
.course-dates-macro .overlay .selection ul li .book-now.region a {
  min-width: 66px;
  width: 90px;
  padding: 5px 3px;
  height: 32px;
}
.course-dates-macro .overlay .selection ul li .book-now.region a span {
  padding-left: 5px;
  margin-right: 5px;
  float: right;
  font-weight: 500;
  transform: rotate(90deg);
}
.course-dates-macro .overlay .selection ul li .book-now.region .dropdown-container {
  padding-right: 8px;
  padding-left: 16px;
  background-color: white;
}
.course-dates-macro .overlay .selection ul li .book-now.region .dropdown-container a {
  background-color: white;
  margin-left: 0px;
  padding: 0px;
  width: 17px;
  color: black;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .course-dates-macro .toolbar {
    display: block;
  }
  .course-dates-macro .toolbar .selected.mobile {
    display: none;
  }
  .course-dates-macro .toolbar div {
    border-radius: 3px;
    width: 25%;
    float: left;
    text-align: center;
    background-color: #e5ebee;
    color: #003057;
    padding: 0.3em !important;
    margin-bottom: 3px;
  }
  .course-dates-macro .toolbar div h2 {
    padding-top: 5px;
  }
  .course-dates-macro .toolbar div.course {
    width: 48%;
    margin: 1%;
    box-shadow: 0px 2px 3px 0px grey;
  }
}
@media only screen and (max-width: 767.98px) and (max-width: 350px) {
  .course-dates-macro .toolbar div.course {
    width: 98%;
  }
}
@media (max-width: 767.98px) {
  .course-dates-macro .traffic {
    top: 8px;
  }
  .course-dates-macro .color-container {
    padding: 0px !important;
    background-color: white !important;
    margin-top: 10px;
  }
  .course-dates-macro .color-container > .title,
  .course-dates-macro .color-container .date-list-container {
    display: none;
  }
  .course-dates-macro .color-container .dates {
    padding: 0px !important;
    margin: 0px;
  }
  .course-dates-macro .color-container .dates .trafficlight-mobile {
    display: block !important;
  }
  .course-dates-macro .color-container .dates .date-mobile {
    display: block !important;
  }
  .course-dates-macro .color-container .dates .date-row.heading {
    display: none;
  }
  .course-dates-macro .color-container .dates .date-row {
    box-shadow: 0px 2px 3px 0px grey;
    display: block;
    height: 85px;
    padding-top: 0px !important;
  }
  .course-dates-macro .color-container .dates .date-row:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
  .course-dates-macro .color-container .dates .date-row:nth-of-type(even) {
    background-color: #e5ebee;
  }
  .course-dates-macro .color-container .dates .date-row .date {
    display: none;
  }
  .course-dates-macro .color-container .dates .date-row .date-mobile {
    width: 85px;
    padding-left: 7px;
    height: 69px;
    text-align: center;
    font-size: 17px;
    line-height: 25px;
    grid-column: 1/2 !important;
    grid-row: 1/span 3 !important;
  }
  .course-dates-macro .color-container .dates .date-row .date-mobile .day {
    font-size: 25px;
    font-weight: 500;
  }
  .course-dates-macro .color-container .dates .date-row .title {
    font-size: 14px;
    font-weight: 500;
    height: 23px;
    width: 165px;
    grid-column: 2/3 !important;
    grid-row: 1/2 !important;
  }
}
@media only screen and (max-width: 767.98px) and (max-width: 350px) {
  .course-dates-macro .color-container .dates .date-row .title {
    font-size: 13px;
    width: 130px;
  }
}
@media (max-width: 767.98px) {
  .course-dates-macro .color-container .dates .date-row .city {
    height: 23px;
    grid-column: 2/3 !important;
    grid-row: 2/3 !important;
  }
  .course-dates-macro .color-container .dates .date-row .trafficlight-mobile {
    height: 23px;
    grid-column: 2/3 !important;
    grid-row: 3/4 !important;
  }
  .course-dates-macro .color-container .dates .date-row .trafficlight-mobile span {
    font-weight: 500;
  }
  .course-dates-macro .color-container .dates .date-row .trafficlight {
    grid-column: 3/4 !important;
    grid-row: 1/span 3 !important;
    display: none;
  }
  .course-dates-macro .color-container .dates .date-row .book {
    grid-column: 4/5 !important;
    grid-row: 2/3 !important;
    top: -13px;
    width: 77px;
    height: 27px;
  }
  .course-dates-macro .color-container .dates .date-row .book a {
    border-radius: 0px;
    font-size: 16px;
  }
  .course-dates-macro .color-container .dates .date-row .book .now {
    display: none;
  }
  .course-dates-macro .color-container .dates .course-date-list {
    display: none;
  }
  .course-dates-macro .color-container .dates .course-date-list .mobile-title {
    display: none;
    background-color: #003057;
    color: white;
    padding: 0.5em;
    margin-top: 0.5em;
    text-align: center;
    position: relative;
    cursor: pointer;
  }
  .course-dates-macro .color-container .dates .course-date-list .mobile-title:after {
    content: "+";
    position: absolute;
    right: 20px;
  }
  .course-dates-macro .color-container .dates .course-date-list.selected .mobile-title {
    background-color: #FF3506;
  }
  .course-dates-macro .color-container .dates .course-date-list.selected .mobile-title:after {
    content: "-";
  }
  .course-dates-macro .color-container .dates .course-date-list.selected .date-list-container {
    display: block;
  }
}
.course-dates-macro .scheduled {
  background-color: #17cf4b;
}
.course-dates-macro .confirmed {
  background-color: #ffbf00;
}
.course-dates-macro .fewSeats {
  background-color: #fd7300;
}
.course-dates-macro .soldOut {
  background-color: #810081;
}
.course-dates-macro .scheduled-text {
  color: #0d732a;
}
.course-dates-macro .confirmed-text {
  color: #806000;
}
.course-dates-macro .fewSeats-text {
  color: #974500;
}
.course-dates-macro .soldOut-text {
  color: #810081;
}
.course-dates-macro .traffic {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: relative;
  top: -2px;
}
.course-dates-macro .toolbar {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.course-dates-macro .toolbar div {
  display: inline-block;
  background-color: #003057;
  padding: 0.6em;
  cursor: pointer;
  color: white;
  font-size: 0.8em;
}
@media (max-width: 767.98px) {
  .course-dates-macro .toolbar div {
    background-color: #e5ebee;
    border: solid 1px;
  }
}
.course-dates-macro .toolbar div.selected {
  color: #003057;
  background-color: #f5f5f5;
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .course-dates-macro .toolbar div.selected {
    background-color: white;
  }
}
.course-dates-macro .toolbar div.selected h2 {
  color: #003057;
  font-size: 1em;
}
@media (max-width: 767.98px) {
  .course-dates-macro .toolbar div.selected h2 {
    font-weight: 500;
  }
}
.course-dates-macro .toolbar div h2 {
  font-size: 1em;
  color: white;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .course-dates-macro .toolbar div h2 {
    color: #003057;
    font-weight: 500;
  }
}
.course-dates-macro .color-container {
  padding: 1em;
  background-color: #f5f5f5;
  flex: 1;
}
.course-dates-macro .color-container > .title {
  font-size: 14px;
  font-weight: bold;
}
.course-dates-macro .color-container > .title div {
  display: inline-block;
}
.course-dates-macro .color-container .dates {
  padding: 10px 1em;
}
.course-dates-macro .color-container .dates > div {
  display: none;
}
.course-dates-macro .color-container .dates .selected {
  display: block;
}
.course-dates-macro .color-container .dates .date-row {
  margin-bottom: 10px;
  font-size: 13px;
  border-bottom: 1px solid #dddddd;
  padding: 0.5em 0;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "date title city trafficlighttext trafficlight book";
  grid-template-columns: 2fr 3fr 1fr 1fr 68px 80px;
}
.course-dates-macro .color-container .dates .date-row .trafficlight-mobile {
  display: none;
}
.course-dates-macro .color-container .dates .date-row .date-mobile {
  display: none;
}
.course-dates-macro .color-container .dates .date-row.heading {
  text-align: left;
  font-weight: 500;
}
@media (min-width: 1586px) {
  .course-dates-macro .color-container .dates .date-row.heading {
    grid-template-areas: "date title city trafficlight trafficlight book";
    grid-template-columns: 2fr 3fr 1fr 1fr 68px 80px;
  }
}
.course-dates-macro .color-container .dates .date-row.heading .book {
  text-align: left;
}
@media (max-width: 1585.9px) {
  .course-dates-macro .color-container .dates .date-row {
    grid-template-areas: "date title city trafficlight book";
    grid-template-columns: 2fr 3fr 80px 68px 80px;
  }
  .course-dates-macro .color-container .dates .date-row .trafficlight-text {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .course-dates-macro .color-container .dates .date-row {
    grid-template-columns: 100px 3fr 1fr 1fr;
    grid-template-rows: 20px 17px;
  }
  .course-dates-macro .color-container .dates .date-row.heading .trafficlight {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .course-dates-macro .color-container .dates .date-row {
    grid-template-columns: 85px auto;
    grid-template-rows: 20px 17px;
    grid-template-areas: "trafficlight date title" ". city book";
    margin-bottom: 10px;
  }
  .course-dates-macro .color-container .dates .date-row.heading .city {
    display: none;
  }
  .course-dates-macro .color-container .dates .date-row .book a {
    width: 100%;
    font-size: 16px !important;
    padding: 3px 10px;
    text-transform: lowercase;
    font-family: Interstate;
  }
}
.course-dates-macro .color-container .dates .date-row .date {
  grid-area: date;
}
.course-dates-macro .color-container .dates .date-row .title {
  grid-area: title;
}
.course-dates-macro .color-container .dates .date-row .city {
  grid-area: city;
}
.course-dates-macro .color-container .dates .date-row .book {
  grid-area: book;
  text-align: right;
  position: relative;
  top: -7px;
}
.course-dates-macro .color-container .dates .date-row .book a {
  display: inline-block;
  min-width: 79px;
  text-align: center;
  font-size: 11px;
}
@media (max-width: 575.98px) {
  .course-dates-macro .color-container .dates .date-row .book a {
    width: 60px;
    min-width: 60px;
    margin-right: 10px;
  }
}
.course-dates-macro .color-container .dates .date-row .trafficlight {
  grid-area: trafficlight;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 350px) {
  .course-dates-macro .color-container .dates .date-row .trafficlight {
    display: none;
  }
}
.course-dates-macro .color-container .dates .date-row .trafficlight-text {
  grid-area: trafficlighttext;
}
.course-dates-macro .color-container .book a {
  border-radius: 20px;
  color: white;
  padding: 4px 10px;
}
.course-dates-macro .color-container .book a.scheduled, .course-dates-macro .color-container .book a.confirmed, .course-dates-macro .color-container .book a.fewSeats {
  background-color: #0064F0;
}
.course-dates-macro .actions {
  margin-top: 1em;
  margin-bottom: 1em;
}
.course-dates-macro .actions button {
  padding: 5px 20px;
  font-size: 12px;
  border: 0;
  outline: 0;
  color: white;
  background-color: #003057;
}
@media (max-width: 762px) {
  .course-dates-macro .actions button {
    width: 49%;
  }
}
.course-dates-macro .explain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 13px;
  margin-top: 2em;
}
.course-dates-macro .explain > div {
  margin-bottom: 1em;
  min-width: 140px;
}
@media (max-width: 762px) {
  .course-dates-macro .explain {
    display: none;
  }
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.course-blue-box.rating .parking-icon {
  background-color: transparent;
}

.course-blue-box {
  background-color: #f5f5f5;
  margin-bottom: 1em;
  font-size: 0.8em;
  width: 100%;
}
.course-blue-box .columns-2 {
  columns: 2;
}
.course-blue-box h2 {
  font-size: 1em !important;
  font-weight: 400 !important;
  padding: 0;
  margin: 0;
  line-height: inherit;
}
.course-blue-box iframe {
  flex: 1;
  width: 100%;
}
.course-blue-box.large {
  flex: 1;
  min-height: 380px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1199.98px) {
  .course-blue-box.large {
    min-height: 0px;
    height: 100%;
  }
}
.course-blue-box.small {
  min-height: 190px;
}
@media (max-width: 1199.98px) {
  .course-blue-box.small {
    min-height: 0px;
  }
}
.course-blue-box > .title, .course-blue-box > .body > .title {
  background-color: #003057;
  padding: 0.6em;
  cursor: pointer;
  color: white;
  margin-bottom: 1em;
}
@media (max-width: 762px) {
  .course-blue-box > .title, .course-blue-box > .body > .title {
    color: #003057;
    font-size: 18px !important;
    font-weight: 500;
    background-color: white;
  }
}
@media (max-width: 762px) {
  .course-blue-box > .title.accordion, .course-blue-box > .body > .title.accordion {
    background-color: white;
    color: #003057;
    -webkit-box-shadow: -2px 2px 5px 0px #95b8d4;
    box-shadow: -2px 2px 5px 0px #95b8d4;
    border-radius: 5px;
    font-size: 20px !important;
    font-weight: 500;
  }
  .course-blue-box > .title.accordion h2, .course-blue-box > .body > .title.accordion h2 {
    font-size: 16px !important;
    font-weight: 400;
    padding-top: 4px;
  }
}
@media (max-width: 1199.98px) {
  .course-blue-box > .title, .course-blue-box > .body > .title {
    margin-bottom: 0px;
  }
}
.course-blue-box > .title.no-pad, .course-blue-box > .body > .title.no-pad {
  margin-bottom: 0;
}
.course-blue-box > .title p, .course-blue-box > .body > .title p {
  margin-bottom: 0px;
}
.course-blue-box .bluebox-row {
  color: #003057;
  padding: 3px 20px;
  display: flex;
  justify-content: space-between;
  div-display: inline-block;
}
.course-blue-box .bluebox-row > .address {
  text-align: right;
  max-width: 120px;
}
.course-blue-box .prophidden {
  display: none;
}
.course-blue-box .stars {
  color: #ffb900 !important;
}
.course-blue-box .starstittle {
  color: #ffb900 !important;
  float: right;
}
.course-blue-box .contact-form {
  margin: 1em;
}
@media (max-width: 762px) {
  .course-blue-box .contact-form {
    margin: 0px;
  }
  .course-blue-box .contact-form.active {
    margin: 1em;
  }
}
.course-blue-box .parking-icon {
  display: inline-block;
  background-color: #0064F0;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 52px;
  font-weight: bold;
}
.course-blue-box .content-with-icon {
  display: flex;
}
.course-blue-box .content-with-icon > *:last-child {
  flex: 1;
}
.course-blue-box .city-links {
  color: #9c5407;
  margin: 1em;
  columns: 1;
}
@media (max-width: 762px) {
  .course-blue-box .city-links {
    margin: 0px;
  }
}
.course-blue-box .city-links ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.course-blue-box .city-links ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}
.course-blue-box .city-links a {
  color: #9c5407;
}
.course-blue-box .city-links a:visited {
  color: #9c5407;
}
.course-blue-box .city-links-courses {
  color: #9c5407;
  margin: 1em;
  columns: 1;
}
@media (max-width: 762px) {
  .course-blue-box .city-links-courses {
    margin: 0px;
    min-height: auto;
  }
}
.course-blue-box .city-links-courses ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.course-blue-box .city-links-courses ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}
.course-blue-box .city-links-courses a {
  color: #9c5407;
}
.course-blue-box .city-links-courses a:visited {
  color: #9c5407;
}
.course-blue-box .other-courses {
  margin-top: 1em;
}
@media (max-width: 762px) {
  .course-blue-box .other-courses {
    margin-top: 0px;
    padding-top: 0px;
    max-height: 401px !important;
  }
}
.course-blue-box .other-courses ul {
  list-style-type: none;
  padding: 0em 0px;
  padding-left: 10px;
}
.course-blue-box .other-courses ul li {
  display: inline-block;
  margin-left: 0em;
  margin-bottom: 1em;
  padding-right: 10px;
}
.course-blue-box .course-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5px;
}
.course-blue-box .course-content > p {
  display: none;
}
body.isIE11 .course-blue-box .course-content {
  display: block;
  columns: 5;
}
@media (max-width: 991.98px) {
  body.isIE11 .course-blue-box .course-content {
    columns: 3;
  }
}
@media (max-width: 767.98px) {
  body.isIE11 .course-blue-box .course-content {
    columns: 1;
  }
}

.course-blue-box .course-content ul {
  margin-left: 1.5em;
  font-size: 11px;
}
.course-blue-box .course-content ul li {
  padding-left: 12px;
}
.course-blue-box .course-content ul li:first-child {
  list-style: none;
  font-size: 14px;
  margin-left: -1em;
  position: relative;
  padding-left: 24px;
}
.course-blue-box .course-content ul li:first-child:before {
  position: absolute;
  left: 0;
  content: " ";
  display: block;
  background-size: 100% auto;
  height: 21.3333333333px;
  width: 21.3333333333px;
}
@media (max-width: 762px) {
  .course-blue-box .course-content ul {
    margin-left: 0px;
    font-size: 15px;
  }
  .course-blue-box .course-content ul li:not(:first-child) {
    display: none;
  }
  .course-blue-box .course-content ul li:first-child::after {
    content: "+";
    position: absolute;
    z-index: 1;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 500;
  }
  .course-blue-box .course-content ul li.active::after {
    content: "-";
    position: absolute;
    z-index: 1;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 500;
  }
  .course-blue-box .course-content ul li:first-child:before {
    display: none;
  }
}
.course-blue-box h1,
.course-blue-box h2,
.course-blue-box h3,
.course-blue-box h4 {
  font-size: 1.2em;
}

.course-blue-box-mobile {
  padding-bottom: 20px;
}
.course-blue-box-mobile .bluebox-row-mobile {
  width: 33.33%;
  float: left;
  font-size: 14px;
}
.course-blue-box-mobile .bluebox-row-mobile .icon-font-clock {
  color: #003057;
}
.course-blue-box-mobile .bluebox-row-mobile .icon-font-price-tags {
  padding-top: 3px;
  font-size: 14px;
  color: #003057;
}
.course-blue-box-mobile .bluebox-row-mobile .icon-font-users {
  color: #003057;
}
.course-blue-box-mobile .bluebox-row-mobile div {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.pricesmobile {
  background-color: #d1def1;
  display: none;
  padding-top: 10px;
  padding-bottom: 12px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 2px 3px 0px grey;
  box-shadow: 0px 2px 3px 0px grey;
}
@media (max-width: 991.98px) {
  .pricesmobile {
    display: block;
  }
}

.courses-slider {
  background-color: white;
}
.courses-slider .items {
  padding-top: 25px;
  margin: 1em;
}
@media (max-width: 762px) {
  .courses-slider .items {
    padding-top: 0px;
  }
}
.courses-slider .items .slick-arrow {
  color: #f5f5f5;
  background-color: #636060;
  border-radius: 100%;
}
.courses-slider .items .item-wrap {
  text-align: center;
}
.courses-slider .items .item {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70px;
  min-height: 70px;
}

.courses-slider.desktop {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}
.courses-slider.desktop .item {
  width: 70px;
  height: 70px;
  float: left;
}
@media (max-width: 762px) {
  .courses-slider.desktop {
    display: none;
  }
}

.courses-slider.mobile {
  display: none;
}
@media (max-width: 762px) {
  .courses-slider.mobile {
    display: block;
  }
}
.courses-slider.mobile .glider-contain .glider-slide {
  min-width: 0;
}
.courses-slider.mobile .glider-contain .glider-prev, .courses-slider.mobile .glider-contain .glider-next {
  top: 25px;
  font-size: 25px;
}
.courses-slider.mobile .glider-contain .glider-prev {
  padding-left: 7px;
}
.courses-slider.mobile .glider-contain .glider-next {
  padding-right: 7px;
  right: -23px;
}

.city-slider {
  background-color: white;
}
@media (min-width: 414px) and (max-width: 762px) {
  .city-slider {
    margin-left: 10px;
  }
}
.city-slider .items {
  display: block;
  padding-top: 25px;
}
@media (max-width: 762px) {
  .city-slider .items {
    padding-top: 0px;
  }
}
.city-slider .items .item {
  margin: 0 5px 10px;
  float: left;
}
@media (max-width: 762px) {
  .city-slider .items .item {
    margin: 0 1px 10px;
  }
}
.city-slider .items .slick-arrow {
  color: #f5f5f5;
  background-color: #636060;
  border-radius: 100%;
}
.city-slider .items .item-wrap {
  text-align: center;
}
.city-slider .items .item {
  text-align: center;
  width: 80px;
  min-height: 80px;
}
@media (min-width: 414px) and (max-width: 762px) {
  .city-slider .items .item {
    width: 80px;
    min-height: 80px;
    margin-right: 5px;
  }
}

@media (max-width: 762px) {
  .noshowmobile {
    display: none;
  }
  .mobile {
    padding-top: 15px;
  }
  .mobiletittle {
    display: none;
  }
  .cityabbr {
    width: 71px;
    height: 71px;
    float: left;
    -webkit-box-shadow: -2px 2px 5px 0px #95b8d4;
    box-shadow: -2px 2px 5px 0px #95b8d4;
    border: none;
    border-radius: 10px;
  }
  .cityabbr p {
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    line-height: 12px;
    padding-top: 26px;
    color: #003057;
  }
  .cityabbr p span {
    font-size: 11px;
  }
  .city-slider .title {
    padding-left: 3px;
  }
  .city-slider .city-links .slick-list {
    height: 100px;
  }
  .city-slider .city-links .cityabbr {
    width: 80px;
    min-height: 80px;
  }
}
.macro-course-structure .download {
  font-size: 12px;
}
.macro-course-structure .download a {
  color: #9c5407;
}
.macro-course-structure .view-course {
  font-size: 11px;
  background-color: #0064F0;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
  margin-right: 1em;
  min-width: 85px;
  text-align: center;
}
body.isIE11 .macro-course-structure .view-course {
  text-align: center;
}

@media (max-width: 762px) {
  .macro-course-structure .view-course {
    border-radius: 0px;
    font-size: 16px;
  }
}
.macro-course-structure ul {
  list-style: none;
  margin-left: 1em;
  margin-top: 0.5em;
  padding: 0;
}
.macro-course-structure li {
  overflow: hidden;
  position: relative;
  padding: 0 0 23px 62px;
}
@media (max-width: 762px) {
  .macro-course-structure li {
    padding: 0 0 13px 62px;
  }
}
.macro-course-structure li .row {
  min-height: 5em;
}
.macro-course-structure li:first-child::before {
  top: 24px;
}
.macro-course-structure li::before {
  content: "";
  position: absolute;
  left: 19px;
  top: 0px;
  width: 20px;
  height: 999px;
  border: 2px solid #e2e2e2;
  border-width: 0px 0 0 9px;
}
@media (max-width: 762px) {
  .macro-course-structure li::before {
    border: 0px;
  }
}
.macro-course-structure li:last-child::before {
  top: auto;
  bottom: 40px;
  border-width: 0px 0px 0px 9px;
}
.macro-course-structure li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 48px;
  height: 48px;
  background-color: #e2e2e2;
  border: 2px solid #e2e2e2;
  border-radius: 50%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 762px) {
  .macro-course-structure li::after {
    background-color: none;
    border: 0px;
    background-size: 48px 48px;
    border-radius: 0%;
  }
}
.macro-course-structure li.selected {
  color: #000;
  font-weight: 500;
}
.macro-course-structure li.selected::before {
  border-top-color: #003057;
}
.macro-course-structure li.selected::after {
  border-color: #003057;
}
@media (max-width: 762px) {
  .macro-course-structure .courses {
    display: none;
  }
  .macro-course-structure .row .col:first-child {
    padding-top: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .macro-course-structure .row .col:nth-of-type(2) {
    -webkit-box-flex: 0;
    margin-top: -13px;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.awards.macro-automatic-icon-list {
  display: none;
}

@media (max-width: 762px) {
  .cattittle {
    display: none;
  }
}

@media (max-width: 762px) {
  .cattittle.workplace {
    display: block;
  }
  .cattittle.workplace p {
    font-size: 20px;
  }
}

.macro-automatic-icon-list.template .fixed-icon {
  width: 81px;
  height: 81px;
}
@media (max-width: 762px) {
  .macro-automatic-icon-list.template a {
    color: #003057;
    font-size: 16px;
  }
}

@media (max-width: 762px) {
  .macro-automatic-icon-list.template {
    background-color: #e5ebee;
  }
  .macro-trusted-by-business.macro-automatic-icon-list.theme-white {
    background-color: white;
  }
  .macro-trusted-by-business.macro-automatic-icon-list.theme-white .heading {
    font-size: 18px;
    font-weight: 500;
  }
  .awards-home {
    display: none;
  }
  .macro-automatic-icon-list.macro-why-nexacu {
    display: none;
  }
  .awards.macro-automatic-icon-list.theme-white {
    display: block;
    background-color: white;
  }
  .awards.macro-automatic-icon-list.theme-white .heading {
    font-size: 18px;
    font-weight: 500;
  }
}
.macro-automatic-icon-list {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  color: white;
  background-color: #003057;
}
@media (min-width: 576px) {
  .macro-automatic-icon-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-automatic-icon-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-automatic-icon-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-automatic-icon-list {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-automatic-icon-list {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
.macro-automatic-icon-list.theme-white {
  background-color: #f2eded;
  color: #003057;
}
.macro-automatic-icon-list.theme-white a {
  color: #003057;
}
.macro-automatic-icon-list.theme-white a:hover {
  color: #003057 !important;
}
.macro-automatic-icon-list.theme-white-white {
  background-color: #fff;
  color: #003057;
  padding-top: 0px;
  padding-bottom: 2 em;
  text-align: center;
}
.macro-automatic-icon-list.theme-white-white a {
  color: #003057;
}
.macro-automatic-icon-list.theme-white-white a:hover {
  color: #003057 !important;
}
.macro-automatic-icon-list.theme-white-white ul {
  margin: 0 auto;
  text-align: center;
  display: inline-flex;
}
.macro-automatic-icon-list.theme-white-white ul li {
  float: left;
  max-width: 500px;
  margin: 0px 4em;
}
@media (max-width: 1420px) {
  .macro-automatic-icon-list.theme-white-white ul li {
    margin: 0px 3em;
  }
}
@media (max-width: 1200px) {
  .macro-automatic-icon-list.theme-white-white ul li {
    margin: 0px 2.5em;
  }
}
@media (max-width: 1000px) {
  .macro-automatic-icon-list.theme-white-white ul li {
    margin: 0px 1em;
  }
}
@media (max-width: 550px) {
  .macro-automatic-icon-list.theme-white-white ul li {
    margin: 0px 3em 2em 4em;
  }
}
.macro-automatic-icon-list.theme-white-real {
  background-color: #f6f3f3;
}
.macro-automatic-icon-list.theme-light-blue {
  background-color: #0064F0;
}
@media (max-width: 767.98px) {
  .macro-automatic-icon-list.macro-trusted-by-business ul {
    display: flex;
    flex-wrap: wrap;
  }
  .macro-automatic-icon-list.macro-trusted-by-business li {
    display: none;
  }
  .macro-automatic-icon-list.macro-trusted-by-business li:nth-of-type(-n + 6) {
    display: block;
  }
}
.macro-automatic-icon-list h1 {
  font-weight: 100;
}
.macro-automatic-icon-list a {
  color: white;
}
.macro-automatic-icon-list a:hover {
  color: white;
}
.macro-automatic-icon-list ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
}
.macro-automatic-icon-list ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 1em;
  max-width: 300px;
  text-align: center;
}
.macro-automatic-icon-list ul li img {
  margin: auto;
}
.macro-automatic-icon-list ul li h3 {
  font-size: inherit;
  margin-bottom: 0;
  font-weight: inherit;
  line-height: inherit;
}
@media (min-width: 414px) and (max-width: 762px) {
  .macro-automatic-icon-list ul.listmobile li {
    max-width: 100px !important;
  }
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-three-image-text {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  color: white;
  background-color: #003057;
}
@media (min-width: 576px) {
  .macro-three-image-text {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-three-image-text {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-three-image-text {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-three-image-text {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-three-image-text {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
.macro-three-image-text h1 img {
  display: none;
}
@media (min-width: 1200px) {
  .macro-three-image-text h1 img {
    display: block;
  }
}
.macro-three-image-text.theme-alt-blue {
  background-color: #034b88;
}
.macro-three-image-text.theme-white-left {
  color: #003057;
  background-color: #e9e9e9;
}
.macro-three-image-text.theme-white-left .col {
  text-align: left;
}
.macro-three-image-text .col {
  text-align: center;
}
.macro-three-image-text .col img {
  margin-bottom: 1em;
}
.macro-three-image-text .col .content {
  font-size: 15px;
  max-width: 19em;
  display: inline-block;
}
.macro-three-image-text .title-container {
  font-size: 24px;
}
.macro-three-image-text a.btn {
  display: inline-block;
  background-color: #FF3506;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 0;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-workplace-booking-course-outline {
  background-color: #e5ebee;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  color: #003057;
}
@media (min-width: 576px) {
  .macro-workplace-booking-course-outline {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-workplace-booking-course-outline {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-workplace-booking-course-outline {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-workplace-booking-course-outline {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-workplace-booking-course-outline {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media (max-width: 575.98px) {
  .macro-workplace-booking-course-outline {
    text-align: center;
  }
}
@media (max-width: 762px) {
  .macro-workplace-booking-course-outline h1 {
    font-size: 24px;
  }
}
.macro-workplace-booking-course-outline .learntouse {
  display: none;
}
.macro-workplace-booking-course-outline .items {
  text-align: center;
}
.macro-workplace-booking-course-outline .item {
  display: inline-block;
  text-align: center;
  margin: 1em 3em;
  width: 130px;
}
@media (max-width: 767.98px) {
  .macro-workplace-booking-course-outline .item {
    display: inline-block;
    width: 80px;
    margin: 1em 1em;
    font-size: 14px;
  }
  .macro-workplace-booking-course-outline .item .learntouse {
    display: block;
  }
}
.macro-workplace-booking-course-outline .item .icon {
  margin: 10px 0em;
}
@media (max-width: 762px) {
  .macro-workplace-booking-course-outline .item .icon {
    width: 80px;
  }
  .macro-workplace-booking-course-outline .item .icon img {
    width: 64px;
  }
}
.macro-workplace-booking-course-outline .item .title {
  font-weight: 500;
}
.macro-workplace-booking-course-outline .item .download {
  display: block;
  background-color: #FF3506;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
}
@media (max-width: 762px) {
  .macro-workplace-booking-course-outline .item .download {
    display: none;
  }
}

@media (max-width: 762px) {
  .theme-normal.certificationPath.first.noshow {
    display: none;
  }
}

@media (max-width: 762px) {
  .theme-normal.certificationPath.first.noshow.first.blue {
    display: block !important;
  }
  .theme-normal.certificationPath.first.noshow.first.blue .stars {
    display: none;
  }
  .theme-normal.certificationPath.first.noshow.first.blue .link {
    display: none;
  }
  .theme-normal.certificationPath.first.noshow.first.blue .button-container {
    display: none;
  }
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-quote {
  background-color: #221f1f;
  color: white;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  font-size: 40px;
  text-align: center;
}
@media (min-width: 576px) {
  .macro-quote {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-quote {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-quote {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-quote {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-quote {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}

.macro-quote.certification {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  font-size: 30px;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-checklist-success {
  background-color: #003057;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  color: white;
}
@media (min-width: 576px) {
  .macro-checklist-success {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-checklist-success {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-checklist-success {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-checklist-success {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-checklist-success {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media (max-width: 575.98px) {
  .macro-checklist-success {
    text-align: center;
  }
}
.macro-checklist-success h1 {
  font-size: 36px;
  font-weight: 100;
}
.macro-checklist-success ul {
  list-style: none;
  display: inline-block;
  max-width: 30%;
  margin: 2em 2em;
  float: left;
  height: 145px;
}
@media (max-width: 767.98px) {
  .macro-checklist-success ul {
    width: 80%;
    max-width: 80%;
    display: block;
  }
}
.macro-checklist-success ul li:first-child {
  font-size: 23px;
  margin-bottom: 0.4em;
  position: relative;
}
.macro-checklist-success ul li:first-child:after {
  display: block;
  content: " ";
  height: 30px;
  width: 30px;
  background-image: url("../core/images/checkbox.png");
  background-size: 30px 30px;
  position: absolute;
  left: -60px;
  top: 8px;
  float: left;
}
.macro-checklist-success ul li {
  font-size: 13px;
}

@media (max-width: 762px) {
  .macro-checklist-success.theme-workplace {
    display: none;
  }
  .workplace.extracontent {
    display: none;
  }
}
/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-quote-list {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: #0064F0;
  color: white;
}
@media (min-width: 576px) {
  .macro-quote-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-quote-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-quote-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-quote-list {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-quote-list {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media (max-width: 767.98px) {
  .macro-quote-list {
    padding: 2em 3em;
  }
}
.macro-quote-list .quote-icon {
  display: block;
  height: 46px;
  background-size: 43px 23px;
  background-image: url("../core/images/quote.png");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.macro-quote-list .glider-contain .glider-prev, .macro-quote-list .glider-contain .glider-next {
  top: 25px;
  font-size: 25px;
}
.macro-quote-list .glider-contain .glider-prev {
  padding-left: 10px;
}
.macro-quote-list .glider-contain .glider-next {
  padding-right: 10px;
  right: -23px;
}
.macro-quote-list .quote-items {
  text-align: center;
}
.macro-quote-list .quote-items .item {
  padding: 0em 2em;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-online-course-details {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (min-width: 576px) {
  .macro-online-course-details {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-online-course-details {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-online-course-details {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-online-course-details {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-online-course-details {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
.macro-online-course-details .course-instance-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.macro-online-course-details .course-blue-box {
  width: initial;
}
.macro-online-course-details .course-instance {
  margin-right: 1em;
  flex-grow: 1;
  width: 300px;
}
.macro-online-course-details .course-instance .outline-text {
  flex-grow: 1;
  background-color: white;
  margin: 2em 1em;
  padding: 1em;
}
.macro-online-course-details .course-instance .body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.macro-online-course-details .course-instance .center {
  text-align: center;
}
.macro-online-course-details .course-instance .outline-download {
  margin-bottom: 1em;
}
.macro-online-course-details .course-instance .outline-download .title {
  font-size: 1.3em;
}
.macro-online-course-details .course-instance .outline-download img {
  margin: 1em 0em;
}
.macro-online-course-details .course-instance .outline-download a {
  color: #f28612;
}
.macro-online-course-details .course-instance .btn {
  margin: 1em 1em;
  background-color: #0064F0;
  color: white;
  border-radius: 0;
  display: block;
}
.macro-online-course-details .face-to-face ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.macro-online-course-details .face-to-face ul li {
  display: inline-block;
  margin: 1em;
}
.macro-online-course-details .face-to-face ul li img {
  width: 38px;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-why-nexacu {
  background-color: #FFFFFF;
  color: #003057;
}
.macro-why-nexacu a {
  color: #003057;
}
.macro-why-nexacu a:hover {
  color: #003057;
}
.macro-why-nexacu ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .macro-why-nexacu ul {
    justify-content: center;
  }
}
.macro-why-nexacu ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.macro-why-nexacu ul li img {
  margin: auto;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-review-slider {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 2em;
  padding-bottom: 2em;
  color: white;
  background-image: url("../core/images/review-pattern.png");
}
@media (min-width: 576px) {
  .macro-review-slider {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .macro-review-slider {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .macro-review-slider {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1200px) {
  .macro-review-slider {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media (min-width: 1900px) {
  .macro-review-slider {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media (max-width: 762px) {
  .macro-review-slider {
    background-image: none;
    background-color: #003057;
  }
  .macro-review-slider .inner {
    display: none;
  }
  .macro-review-slider .text-center {
    display: none;
  }
}
.macro-review-slider.multiline .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 768px) {
  .macro-review-slider.multiline .items .item-wrap {
    flex: 1;
    display: flex;
  }
}
@media (max-width: 767.98px) {
  .macro-review-slider.multiline .items {
    display: block;
  }
  .macro-review-slider.multiline .items .item {
    width: 100%;
  }
}
.macro-review-slider.multiline .pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}
.macro-review-slider.multiline .pagination .disabled {
  pointer-events: none;
}
.macro-review-slider .inner {
  position: relative;
}
@media (min-width: 576px) {
  .macro-review-slider .header {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .macro-review-slider .header {
    justify-content: space-between;
  }
}
.macro-review-slider .header h3 {
  font-weight: 100;
}
@media (min-width: 1200px) {
  .macro-review-slider .header h3 {
    position: absolute;
    left: 0px;
  }
}
.macro-review-slider .header .header-rating-container > div:first-child {
  font-size: 3em;
  color: #FF3506;
}
.macro-review-slider .glider-contain button.glider-prev {
  top: 100px;
  padding-left: 12px;
}
.macro-review-slider .glider-contain button.glider-next {
  top: 100px;
  padding-right: 6px;
  right: -15px;
}
@media (max-width: 767.98px) {
  .macro-review-slider .glider-contain button.glider-next {
    right: -10px;
  }
}
.macro-review-slider .items {
  margin: 1em;
}
.macro-review-slider .items .item-wrap {
  text-align: center;
}
.macro-review-slider .items .item {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #003057;
  margin: auto;
  padding: 2em;
  text-align: center;
  width: 300px;
  font-size: 0.8em;
  min-height: 200px;
}
@media (max-width: 767.98px) {
  .macro-review-slider .items .item {
    width: 200px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .macro-review-slider .items .item {
    width: 322px;
  }
}
.macro-review-slider .items .item a {
  color: #003057;
}
.macro-review-slider .items .item .stars {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
.macro-review-slider .items .item .description {
  flex: 1 1 auto;
  margin: 0.4em 0;
}
.macro-review-slider .items .item .who {
  font-weight: bold;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.macro-offices {
  display: flex;
  background-color: #e5e5e5;
  color: #003057;
}
@media (max-width: 762px) {
  .macro-offices {
    display: none;
  }
}
.macro-offices .microsoftcourses {
  color: #084394;
}
.macro-offices a {
  color: #003057;
}
.macro-offices img {
  margin-right: 1em;
}
@media (max-width: 991.98px) {
  .macro-offices img {
    display: none;
  }
}
.macro-offices ul li {
  position: relative;
  text-align: left;
  width: 150px;
  align-items: flex-start;
}
.macro-offices ul li > .icon {
  position: absolute;
  left: -22px;
  color: #FF3506;
}
.macro-offices ul li .title, .macro-offices ul li > .icon {
  font-size: 1.4em;
}
body.isIE11 .macro-offices ul li {
  display: block !important;
}

div.accordian-p {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  text-align: left;
  margin-bottom: 1em;
}
@media (max-width: 762px) {
  div.accordian-p .accordian-item.selected p:first-child::after {
    content: "-";
    z-index: 1;
    width: 20px;
    float: right;
    font-size: 18px;
    margin-top: -27px;
    font-weight: 500;
  }
  div.accordian-p .accordian-item.selected p.long-text:first-child::after {
    content: "-";
    z-index: 1;
    width: 20px;
    float: right;
    font-size: 18px;
    margin-top: -50px;
    font-weight: 500;
  }
}
div.accordian-p .accordian-item {
  text-align: left;
}
@media (max-width: 762px) {
  div.accordian-p .accordian-item p strong {
    font-weight: 400;
    display: block;
  }
  div.accordian-p .accordian-item p:first-child {
    width: 100%;
  }
  div.accordian-p .accordian-item p:first-child strong {
    padding-right: 20px;
  }
  div.accordian-p .accordian-item p:first-child::after {
    content: "+";
    z-index: 1;
    width: 20px;
    float: right;
    font-size: 18px;
    margin-top: -27px;
    font-weight: 500;
  }
  div.accordian-p .accordian-item p.long-text:first-child::after {
    content: "+";
    z-index: 1;
    width: 20px;
    float: right;
    font-size: 18px;
    margin-top: -50px;
    font-weight: 500;
  }
}
div.accordian-p .accordian-item:first-child {
  text-align: left;
}
div.accordian-p .accordian-item p:first-of-type {
  margin-bottom: 0;
}
div.accordian-p .accordian-item > * {
  display: none;
}
div.accordian-p .accordian-item > *:first-child {
  display: block;
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
div.accordian-p .accordian-item.selected > *:first-child {
  margin: 0em;
}
div.accordian-p .accordian-item.selected > * {
  display: block;
  margin: 1em;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.search-dialog {
  z-index: 999;
  position: absolute;
  background-color: #0064F0;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem 1.5rem;
}
.search-dialog .search-autocomplete {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: #fff;
}
.search-dialog .search-autocomplete:has(.show) {
  padding-top: 10px;
  padding-bottom: 10px;
}
.search-dialog .search-autocomplete li {
  display: none;
}
.search-dialog .search-autocomplete li.show {
  display: block;
}
.search-dialog .search-autocomplete li a {
  display: block;
  padding: 6px 24px;
  color: #0064F0;
  text-decoration: none;
  font-size: 16px;
}
.search-dialog .search-autocomplete li:hover {
  background-color: #0064F0;
}
.search-dialog .search-autocomplete li:hover a {
  color: #fff;
}
.search-dialog.show {
  display: flex;
}

.search-form .search-bar input {
  color: #000;
  border-bottom-color: #000;
}
.search-form .search-bar input::placeholder {
  color: #000;
}
.search-form .search-bar .search-button {
  color: #000;
}

.search-bar {
  width: 100%;
  max-width: 765px;
  position: relative;
}
.search-bar input {
  width: 100%;
  border: none;
  color: #FFF;
  border-bottom: 2px solid #FFF;
  background-color: transparent;
  padding-bottom: 8px;
  padding-right: 3rem;
  font-size: 1.2rem;
}
.search-bar input::placeholder {
  color: #FFF;
}
.search-bar input:focus-visible {
  outline: none;
}
.search-bar .search-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-70%);
  color: #FFF;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-filter ul.search-checks {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
.search-filter ul.search-checks li {
  display: inline;
}
.search-filter ul.search-checks li label {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #003057;
  color: #FFF;
  font-size: 0.8rem;
  margin-bottom: 0;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
  padding: 8px 16px;
  cursor: pointer;
}
.search-filter ul.search-checks li label .icon {
  color: #6d6d6d;
}
.search-filter ul.search-checks li input[type=checkbox]:checked + label {
  background-color: #FF3506;
  color: #fff;
  transition: all 0.2s;
}
.search-filter ul.search-checks li input[type=checkbox]:checked + label .icon {
  color: #fff;
}
.search-filter ul.search-checks li input[type=checkbox] {
  position: absolute;
  opacity: 0;
}

.search-form-wrapper {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
@media (max-width: 991.98px) {
  main.certification-path .comparisons-raw-image {
    display: none;
  }
}
main.certification-path section {
  padding: 20px 20px 20px 20px;
}
main.certification-path section:nth-child(2n+2) {
  background-color: #F9F9F9;
}
@media (min-width: 992px) {
  main.certification-path section {
    padding: 60px 140px 60px 140px;
  }
}
main.certification-path .certification-details-wrapper {
  text-align: center;
}
@media (min-width: 1200px) {
  main.certification-path .certification-details-wrapper .detail-items {
    min-width: 1200px;
  }
}
main.certification-path .certification-details-wrapper .detail-items .vertical-line {
  border-left: 2px solid #A5A5A5;
  width: 5px;
  height: 50px;
}
@media (max-width: 767.98px) {
  main.certification-path .certification-details-wrapper .detail-items .vertical-line {
    display: none;
  }
}
main.certification-path .certification-details-wrapper .detail-items .detail-item {
  margin: 1em 1em;
}
main.certification-path .certification-details-wrapper .detail-items .detail-item .content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
main.certification-path .certification-details-wrapper .detail-items .detail-item .title {
  font-weight: 500;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  main.certification-path .certification-details-wrapper .detail-items .detail-item {
    max-width: 140px;
  }
}
@media (max-width: 767.98px) {
  main.certification-path .certification-details-wrapper .detail-items .detail-item {
    font-size: 14px;
  }
}
@media (max-width: 767.98px) {
  main.certification-path .certification-details-wrapper .detail-items .detail-item {
    font-size: 14px;
  }
}
main.certification-path .key-features-wrapper .key-feature-item {
  margin: 30px;
}
main.certification-path .key-features-wrapper .key-feature-item .key-feature-img {
  width: 500px;
  height: 300px;
  margin: 30px;
  text-align: center;
}
main.certification-path .key-features-wrapper .key-feature-item .key-feature-img img {
  max-width: 500px;
  max-height: 300px;
}
@media (max-width: 767.98px) {
  main.certification-path .key-features-wrapper .key-feature-item .key-feature-img {
    width: 300px;
    height: 300px;
  }
  main.certification-path .key-features-wrapper .key-feature-item .key-feature-img img {
    max-width: 300px;
    max-height: 300px;
  }
}
main.certification-path .key-features-wrapper .key-feature-item .key-feature-description {
  max-width: 700px;
  margin: 30px;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
main.certification-path-root section {
  padding: 20px 20px 20px 20px;
}
main.certification-path-root section:nth-child(2n+2) {
  background-color: #F9F9F9;
}
@media (min-width: 992px) {
  main.certification-path-root section {
    padding: 60px 140px 60px 140px;
  }
}
main.certification-path-root .certification-list-wrapper .certification-items {
  width: 150px;
  padding: 20px;
  min-height: 200px;
  margin: 30px;
}
main.certification-path-root .certification-list-wrapper .certification-items .cert-icon {
  min-height: 60px;
  border-radius: 50%;
}
main.certification-path-root .certification-list-wrapper .certification-items a.btn {
  min-width: 100px;
}
main.certification-path-root .stats-wrapper .donuts-wrapper {
  max-width: 800px;
}
main.certification-path-root .stats-wrapper .donuts-wrapper .stats-item {
  width: 150px;
}
main.certification-path-root .stats-wrapper .donuts-wrapper .stats-item .donut-wrapper {
  width: 140px;
}
main.certification-path-root .stats-wrapper .donuts-wrapper .stats-item .donut-wrapper h3 {
  position: absolute;
  line-height: 140px;
  margin-left: 40px;
}
main.certification-path-root .certification-video-wrapper iframe {
  width: 700px;
  height: 400px;
}
@media (max-width: 767.98px) {
  main.certification-path-root .certification-video-wrapper iframe {
    width: 300px;
    height: 150px;
  }
}

.certification-partials.perks-wrapper {
  padding: 60px 85px 60px 85px;
}
.certification-partials.perks-wrapper .perk-item {
  width: 200px;
  margin: 30px;
  text-align: center;
}
.certification-partials.perks-wrapper .perk-item img {
  max-width: 70%;
}
.certification-partials.perks-wrapper .perk-link {
  min-width: 20px;
  text-align: center;
  margin-top: 100px;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.contact-form-wrapper .main-div {
  width: 450px;
}
.contact-form-wrapper .main-div .form-group.first-name, .contact-form-wrapper .main-div .form-group.city {
  margin-right: 10px;
}
.contact-form-wrapper .spinner {
  margin: 0;
  font-size: 5px;
  display: none;
}
@media (max-width: 1199.98px) {
  .contact-form-wrapper .main-div {
    padding: 30px;
  }
  .contact-form-wrapper .main-div .form-group {
    margin-right: 0;
  }
  .contact-form-wrapper .main-div .form-group.first-name, .contact-form-wrapper .main-div .form-group.city {
    margin-right: 0;
  }
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.partial-view.title-text .title-button {
  max-width: 200px;
}
.partial-view.title-text .title-image img {
  max-width: 100%;
  object-fit: contain;
}
.partial-view.title-text.dark {
  color: white !important;
  background-color: #003057;
}
.partial-view.title-text.dark h1 {
  color: white !important;
}
.partial-view.title-text.dark div .accordian.accordian-p div.accordian-item > *:first-child {
  background-color: inherit;
  border-bottom: 1px solid white;
}
.partial-view.title-text.dark.desktop-view .description a {
  color: #8fbeff;
}
.partial-view.title-text.dark.mobile-view .sub-heading a {
  color: #8fbeff;
}
.partial-view.title-text.desktop-view h1 {
  color: #0064F0;
  font-weight: 500;
  font-size: 36px;
}
.partial-view.title-text.desktop-view h2, .partial-view.title-text.desktop-view h4 {
  font-size: 22px;
  font-weight: 500;
}
.partial-view.title-text.desktop-view .description a {
  color: #0064F0;
}
.partial-view.title-text.mobile-view h1.title {
  font-size: 35px;
}
.partial-view.title-text.mobile-view h1.title p {
  margin-bottom: 0;
}
.partial-view.title-text.mobile-view .title-review .stars {
  padding-bottom: 4px;
}
.partial-view.title-text.mobile-view .title-review .stars i {
  color: #fda80b;
}
.partial-view.title-text.mobile-view .title-review .link a {
  color: white;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.customise-training {
  padding: 50px;
}
.customise-training a.btn {
  width: 250px;
}
.customise-training img {
  max-width: 100%;
}
.customise-training h1.heading {
  color: #0064F0;
  font-weight: 100;
  font-size: 36px;
}
@media (min-width: 992px) {
  .customise-training {
    padding-left: 100px;
  }
  .customise-training .image, .customise-training .content {
    width: 50%;
  }
}

.nav-simple-list-partial p.title {
  font-size: 20px;
  font-weight: 400;
}
.nav-simple-list-partial .simple-list-body .item {
  width: 255px;
  font-size: 16px;
  padding: 15px 0px 15px 20px;
}
.nav-simple-list-partial .simple-list-body .item a {
  font-size: 0.9em;
  color: #003057;
}

.nav-city-list-partial .office {
  width: 250px;
  margin: 25px 30px 25px 30px;
}
.nav-city-list-partial .office .details .title {
  width: 150px;
  font-size: 20px;
  font-weight: 500;
  color: #003057;
}
.nav-city-list-partial .office .details .link {
  width: 100px;
  height: 30px;
  font-size: 13px;
  border-radius: 5px;
  background-color: white;
  border-color: white;
  text-align: center;
  -webkit-box-shadow: -1px 1px 2px 3px #ccc;
  box-shadow: -1px 1px 2px 3px #ccc;
}
.nav-city-list-partial .office .details .link:hover {
  -webkit-box-shadow: -1px 1px 2px 3px #003057;
  box-shadow: -1px 1px 2px 3px #003057;
}
.nav-city-list-partial .office .details .link a {
  color: #003057;
  text-decoration: none;
}
.nav-city-list-partial .office .address {
  clear: both;
  font-size: 12px;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.portal.title-wrapper {
  color: white !important;
  background: #003057;
}
.portal.title-wrapper .subtitle {
  font-size: 20px;
  text-align: center;
  width: 50%;
}
@media (max-width: 767.98px) {
  .portal.title-wrapper .subtitle {
    text-align: left !important;
    width: 75% !important;
  }
}
.portal.image-list {
  background: linear-gradient(to bottom, #003057 50%, white 0%);
}
.portal.image-list .image-item {
  background: white;
  width: 225px;
}
.portal hr {
  border: 1px solid #A5A5A5;
  width: 75%;
}
.portal .tour iframe {
  width: 700px;
  height: 400px;
}
@media (max-width: 767.98px) {
  .portal .tour iframe {
    width: 300px;
    height: 150px;
  }
}

.shared-partial.checkboxlist-panels-wrapper .panel-item {
  width: 250px;
}
.shared-partial.checkboxlist-panels-wrapper .panel-item::before {
  text-align: center;
  transform: rotate(-90deg);
  position: absolute;
  margin-left: -115px;
  margin-top: 100px;
  min-width: 180px;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  font-size: larger;
  background-color: var(--label-color);
  content: var(--label-text);
}
.shared-partial.checkboxlist-panels-wrapper .panel-item .details-wrapper {
  border-radius: 20px;
  min-height: 250px;
}

.partial.timeline-wrapper .timeline-body .book-now {
  margin: 20px 0 30px 0;
  min-width: 50%;
}
.partial.timeline-wrapper .timeline-body .book-now .btn {
  color: white;
  font-size: large;
  width: 100%;
}
@media (min-width: 992px) {
  .partial.timeline-wrapper .timeline-body {
    max-width: 50%;
  }
}
.partial.timeline-wrapper .timeline-list-wrapper {
  padding: 20px 25px 0px 0px;
}
.partial.timeline-wrapper .timeline-list-wrapper .timeline-list {
  list-style-type: none;
}
.partial.timeline-wrapper .timeline-list-wrapper .timeline-list li {
  margin-bottom: 20px;
}
.partial.timeline-wrapper .timeline-list-wrapper .timeline-list .item-container {
  min-height: 100px;
}
.partial.timeline-wrapper .timeline-list-wrapper .timeline-list .path-line {
  background-color: #A5A5A5;
  width: 2px;
  height: 100%;
  margin-top: 10px;
}
.partial.timeline-wrapper .timeline-list-wrapper .timeline-list .blue-dot {
  width: 40px;
  text-align: center;
}
.partial.timeline-wrapper .timeline-list-wrapper .timeline-list .blue-dot .icon {
  color: #0064F0;
}
@media (min-width: 992px) {
  .partial.timeline-wrapper .timeline-list-wrapper {
    max-width: 30%;
    margin-left: 50px;
  }
}
@media (max-width: 1199.98px) {
  .partial.timeline-wrapper .timeline-list-wrapper {
    margin-top: 30px;
  }
}

.partial.faq-wrapper .faq-body {
  max-width: 900px;
}
.partial.faq-wrapper .faq-item {
  max-width: 300px;
  margin: 30px;
}
.partial.faq-wrapper .faq-item .icon {
  color: #0064F0;
}
.partial.faq-wrapper .faq-item .description {
  margin: 20px 0px 0px 35px;
}

.partial.comparisons-wrapper .certification-comparison {
  width: 250px;
}
.partial.comparisons-wrapper .certification-comparison .foot-notes {
  min-height: 50px;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 10px 15px 10px;
}
.partial.comparisons-wrapper .certification-comparison::before {
  text-align: center;
  transform: rotate(-90deg);
  position: absolute;
  margin-left: -115px;
  margin-top: 180px;
  min-width: 180px;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  font-size: larger;
  background-color: var(--label-color);
  content: var(--label-text);
}
.partial.comparisons-wrapper .certification-comparison .discount-wrapper {
  background-color: #ff3506;
  color: white;
  position: absolute;
  padding: 20px;
  border-radius: 100%;
  text-align: center;
}
.partial.comparisons-wrapper .certification-comparison .details-wrapper {
  margin-top: 70px;
  border-radius: 20px;
}
.partial.comparisons-wrapper .certification-comparison .details-wrapper .price-wrapper {
  min-height: 110px;
}
.partial.comparisons-wrapper .certification-comparison .dotted-spaced {
  background-image: linear-gradient(to right, #D1D3D4 10%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 30px 3px;
  background-repeat: repeat-x;
  height: 20px;
  margin-top: 20px;
}
@media (max-width: 991.98px) {
  .partial.comparisons-wrapper .certification-comparison.other-provider {
    display: none !important;
  }
}

.partial.country-selector-wrapper {
  z-index: 3;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 20px;
}
@media (max-width: 1199.98px) {
  .partial.country-selector-wrapper {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .partial.country-selector-wrapper .extra {
    display: none;
  }
}
.partial.country-selector-wrapper.country-select.region a {
  width: 150px;
  padding: 5px 5px;
  margin-left: 5px;
  border: 1px solid white;
}
@media (max-width: 1199.98px) {
  .partial.country-selector-wrapper.country-select.region a {
    width: 90px;
  }
}
.partial.country-selector-wrapper.country-select.region a img {
  width: 30px;
}
.partial.country-selector-wrapper ul.top {
  display: block;
  top: 0px;
  right: 2em;
}
.partial.country-selector-wrapper ul.top > li {
  min-width: initial;
}
.partial.country-selector-wrapper ul.top > li > a {
  color: white;
  min-width: 100px;
  text-align: center;
  line-height: initial;
  display: inline-block;
  padding: 5px 20px;
}
.partial.country-selector-wrapper ul.top > li .dropdown-container {
  flex-direction: column;
  justify-content: flex-start;
  min-height: initial;
  background-color: white;
  box-shadow: 3px 10px 17px -1px rgba(0, 0, 0, 0.75);
  text-transform: lowercase;
}
.partial.country-selector-wrapper ul.top > li .dropdown-container a {
  display: block;
  margin-bottom: 15px;
  color: white;
}
.partial.country-selector-wrapper ul.top > li .dropdown-container a:last-child {
  margin-bottom: 0px;
}
.partial.country-selector-wrapper > ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-bottom: 0;
}
.partial.country-selector-wrapper > ul > li {
  text-transform: lowercase;
  min-width: 110px;
  font-size: 18px;
  display: inline-block;
}
.partial.country-selector-wrapper > ul > li .expander-toggle {
  display: none;
}
.partial.country-selector-wrapper > ul > li .expander-cat {
  display: none;
}
.partial.country-selector-wrapper > ul > li.country-select a {
  width: 130px;
  display: inline-block;
  background-color: #0064F0;
  padding: 5px 30px;
  margin-left: 5px;
  border: 1px solid white;
}
.partial.country-selector-wrapper > ul > li.country-select a:hover {
  background-color: #005cdc;
}
.partial.country-selector-wrapper > ul > li.country-select.region a {
  min-width: 160px;
  padding: 5px 5px;
  margin-left: 5px;
  border: 1px solid white;
}
@media (max-width: 1199.98px) {
  .partial.country-selector-wrapper > ul > li.country-select.region a {
    width: 90px;
  }
}
.partial.country-selector-wrapper > ul > li.country-select.region a img {
  width: 30px;
}
.partial.country-selector-wrapper > ul > li.country-select.region {
  height: 44px;
}
.partial.country-selector-wrapper > ul > li.country-select.region a {
  min-width: 135px;
  width: 160px;
  padding: 5px 3px;
  height: 32px;
}
.partial.country-selector-wrapper > ul > li.country-select.region a span {
  padding-left: 5px;
  margin-right: 5px;
  float: right;
  font-weight: 500;
  transform: rotate(90deg);
}
.partial.country-selector-wrapper > ul > li.country-select.region .dropdown-container {
  padding-right: 8px;
  padding-left: 16px;
  background-color: white;
}
.partial.country-selector-wrapper > ul > li.country-select.region .dropdown-container a {
  background-color: white;
  margin-left: 0px;
  padding: 0px;
  color: black;
  text-transform: capitalize;
}
.partial.country-selector-wrapper > ul > li.country-select.region .dropdown-container a img {
  padding-right: 10px;
}
.partial.country-selector-wrapper > ul > li > a {
  color: white;
  padding: 0px 5px 5px 5px;
}
.partial.country-selector-wrapper > ul > li > a:hover {
  text-decoration: none;
}
.partial.country-selector-wrapper > ul > li .dropdown-container {
  text-transform: none;
  background-color: white;
  color: #003057;
  min-height: 10em;
  margin-top: 15px;
  width: 100%;
  display: none;
  z-index: 999;
  justify-content: center;
  box-shadow: 1px 10px 13px -7px rgba(0, 0, 0, 0.75);
}
.partial.country-selector-wrapper > ul > li .dropdown-container.nosize {
  width: auto;
  padding: 1em;
  margin-top: 0;
}
.partial.country-selector-wrapper > ul > li .dropdown-container.country-select a {
  border-radius: 0;
  margin: 1em;
  width: 10em;
}
.partial.country-selector-wrapper > ul > li .dropdown-container.about-us {
  flex-direction: column;
  align-items: center;
}
.partial.country-selector-wrapper > ul > li .dropdown-container.about-us .top {
  display: flex;
  width: 100%;
}
.partial.country-selector-wrapper > ul > li .dropdown-container.about-us .bottom .btn {
  margin: 1em 2em;
}
.partial.country-selector-wrapper > ul > li .dropdown-container a {
  color: #003057;
}
.partial.country-selector-wrapper > ul > li .dropdown-container [x-arrow] {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  margin-left: 18px;
  top: -10px;
}
.partial.country-selector-wrapper ul li {
  padding: 0.5em 0;
}
.partial.country-selector-wrapper ul li .dropdown-container {
  display: none;
}
.partial.country-selector-wrapper ul li .dropdown-container a {
  border-bottom: none;
  font-weight: 400;
}
.partial.country-selector-wrapper ul li .country-select.region {
  height: 44px;
}
.partial.country-selector-wrapper ul li .country-select.region a {
  min-width: 66px;
  width: 90px;
  padding: 5px 3px;
  height: 32px;
}
.partial.country-selector-wrapper ul li .country-select.region a span {
  padding-left: 5px;
  margin-right: 5px;
  float: right;
  font-weight: 500;
  transform: rotate(90deg);
}
.partial.country-selector-wrapper ul li .country-select.region .dropdown-container {
  padding-right: 8px;
  padding-left: 16px;
  background-color: white;
}
.partial.country-selector-wrapper ul li .country-select.region .dropdown-container a {
  background-color: white;
  margin-left: 0px;
  padding: 0px;
  width: 17px;
  color: black;
  text-transform: uppercase;
}

.search-wrapper .btn-search {
  padding: 8px 20px;
  background-color: #003057;
  color: white;
  border: none;
}
.search-wrapper .txt-search {
  padding: 6px 20px;
  width: 160px;
}
@media (max-width: 767.98px) {
  .search-wrapper .txt-search {
    margin-bottom: 10px;
  }
  .search-wrapper .txt-search, .search-wrapper .btn-search {
    width: 100%;
  }
}
.search-wrapper .searchimage {
  width: 100px;
}
.search-wrapper.master-view .btn-search {
  background-color: #0064f0;
}

.partial-view.grid-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 300px;
  background: white;
  font-weight: normal;
  font-size: 14px;
}
.partial-view.grid-item .blog-image {
  width: 300px;
}
.partial-view.grid-item .blog-image img {
  max-width: 300px;
}
.partial-view.grid-item .blog-body {
  padding: 30px;
}
.partial-view.grid-item .blog-body .blog-title a {
  color: black;
}
.partial-view.grid-item .blog-footer {
  background: #F9F9F9;
}

.badge {
  background-color: #FF3506;
}

.blog-section article.blog-post-wrapper {
  width: 750px;
}
.blog-section article.blog-post-wrapper .header .title a {
  font-size: 40px;
  color: black;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .blog-section article.blog-post-wrapper .header .title a {
    font-size: 25px;
  }
}
.blog-section article.blog-post-wrapper .header h1 a {
  font-size: 40px;
  color: black;
}
.blog-section article.blog-post-wrapper .main-image {
  padding: 50px 0 50px 0;
}
.blog-section article.blog-post-wrapper .main-image img {
  max-width: 750px;
}
.blog-section article.blog-post-wrapper .blog-meta {
  color: #505050;
}
.blog-section article.blog-post-wrapper .body {
  max-width: 750px;
}
.blog-section article.blog-post-wrapper .body img {
  max-width: 750px;
  height: auto;
}
@media (max-width: 767.98px) {
  .blog-section article.blog-post-wrapper {
    width: 250px;
  }
  .blog-section article.blog-post-wrapper .main-image img {
    max-width: 250px;
  }
  .blog-section article.blog-post-wrapper .body {
    max-width: 250px;
  }
  .blog-section article.blog-post-wrapper .body img {
    max-width: 250px;
  }
}

.top-blog-wrapper {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgb(255, 255, 255) 50%, rgb(242, 242, 242) 50%);
  font-size: 14px;
}
.top-blog-wrapper .blog-title a {
  color: black;
}
.top-blog-wrapper .blog-footer {
  background: white;
}
.top-blog-wrapper .inner-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.top-blog-wrapper .inner-wrapper .blog-body {
  width: 500px;
  background: white;
  padding: 20px;
}

.blog-list-title {
  text-align: center;
}

.blog-grid {
  background-color: #f2f2f2;
}

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
*/
.blog-search {
  width: 750px;
}
@media (max-width: 767.98px) {
  .blog-search {
    width: 100%;
  }
}

.blog-tag.main-wrapper .list-wrapper .tag {
  margin: 10px;
}
.blog-tag.main-wrapper .list-wrapper a {
  background-color: #D1D3D4;
  color: black;
  padding: 5px;
}

.blog-pagination {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 16px;
  display: flex;
  justify-content: center;
}
.blog-pagination .nav-bar {
  display: flex;
  gap: 0.5rem;
}
.blog-pagination .nav-bar * {
  display: inline-block;
}
.blog-pagination .nav-link {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.blog-pagination .nav-link:hover {
  color: #fff;
  background-color: #0064F0;
}
.blog-pagination .icon {
  margin-top: 4px;
}
.blog-pagination .nav-link--disabled, .blog-pagination .nav-link--disabled:hover {
  background-color: transparent;
  color: gray;
}
.blog-pagination .nav-link--active {
  color: #fff;
  background-color: #003057;
}