﻿@import '../../_variables';
.portal {
    &.title-wrapper {
        color: white !important;
        background: #003057;

        .subtitle {
            font-size: 20px;
            text-align: center;
            width: 50%;

            @include media-breakpoint-between(xs, sm) {
                text-align: left !important;
                width: 75% !important;
            }
        }
    }

    &.image-list {
        background: linear-gradient(to bottom, #003057 50%, white 0%);

        .image-item {
            background: white;
            width: 225px;
        }
    }

    hr {
        border: 1px solid #A5A5A5;
        width: 75%;
    }

    .tour iframe {
        width: 700px;
        height: 400px;

        @include media-breakpoint-down(sm) {
            width: 300px;
            height: 150px;
        }
    }
}
