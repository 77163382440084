﻿
.search-wrapper {
    .btn-search {
        padding: 8px 20px;
        background-color: #003057;
        color: white;
        border: none;
    }

    .txt-search {
        padding: 6px 20px;
        width: 160px;
    }

    @include media-breakpoint-down(sm) {
        .txt-search {
            margin-bottom: 10px;
        }

        .txt-search, .btn-search {
            width: 100%
        }
    }

    .searchimage {
        width: 100px;
    }

    &.master-view {
        .btn-search {
            background-color: #0064f0;
        }
    }
}
