@import "../_variables";
@import "../_macros";

.macro-quote-list {
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;
	background-color: $brandBlue;
	color: white;


	@include media-breakpoint-down(sm) {
		padding: 2em 3em;
	}

	.quote-icon {
		display: block;
		//width:86px;
		height: 46px;
		background-size: (86px / 2) (46px / 2);
		background-image: url('../core/images/quote.png');
		background-position: center;
		background-repeat: no-repeat;
		margin-bottom: 1em;
	}

	.glider-contain {

		.glider-prev, .glider-next {
			top: 25px;
			font-size: 25px;
		}

		.glider-prev {
			padding-left: 10px;
		}

		.glider-next {
			padding-right: 10px;
			right: -23px;
		}

	}

	.quote-items {
		text-align: center;

		.item {
			padding: 0em 2em;
		}
	}
}