@import '../_variables';
@import '../_macros';

.first.mobile {
	background-color: $brandNavy;
}
.altertemplate {
	display: none;
	@media (max-width: 762px) {
		display: block;
	}
}

.first.blue {
	display: none;
	@include media-breakpoint-down(md) {
		display: block;
	}
	background-color: $brandNavy;
	margin-top: -11.5px;

	@include media-breakpoint-up(md) {
		margin-top: -49.5px;
	}
	padding-top: 10px;
	z-index: 1;
	.row {
		div {
			.stars {
				float: left;

				.icon-font-star {
					color: #fda80b;
				}
			}
			.link {
				float: left;
				a {
					color: white;
				}

				padding-left: 10px;
				text-decoration: underline;
			}
			color: white;
			h1 {
				text-align: left;
				font-size: 21px;
				margin-top: 10px;
				margin-bottom: 10px;
				color: white;
			}

			p {
				color: white;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
}

.image-text-macro.alter-template {
	background-color: #e5ebee;
	.row {
		div {
			h1 {
				color: $brandNavy;
			}
		}
	}
}

.image-text-macro.certificationPath{
	background-color:$brandNavy;
	margin-top: -2em ;
	@include media-breakpoint-down(sm) {
		
		margin-top: -11px;

	}
	.row {
		div {
			h1 {
				color:white;
			}
			.content{
				color:white;
			}
			}
	}

}

.image-text-macro.alter-template.workplacecourse {
	background-color: $brandNavy;
	color: white !important;
	.row {
		div {
			h1 {
				color: white;
			}
		}
	}
}

.altertemplate {
	background-color: #003057a8;
	color: white;
	padding: 50px;
	font-size: 16px;
}

.altertemplate.workplacecourse {
	background-color: $brandNavy;
	color: white;
}

.altertemplate.workplace {
	padding: 20px;
	background-color: $brandNavy;

	p {
		padding-top: 10px;
		font-size: 18px;
		font-weight: 600;
		font-style: italic;
	}
}

.image-text-macro {
	@include make-default-widget-spacing();

	h1.homemobiletitle {
		font-size: 35px !important;
		font-weight: 1000;
	}

	color: black;
	padding-bottom: 1em;

	h1,
	h2 {
		margin-bottom: 30px;
	}

	h1 {
		margin-top: 1em;
		font-size: 36px;
		font-weight: 100;
		color: $brandBlue;
	}

	&.first {
		h1 {
			font-weight: 500;
		}
	}

	h2 {
		font-size: 22px;
		font-weight: 500;
	}

	.row {
		div:first-child {
			text-align: center;
		}
	}

	@include media-breakpoint-down(md) {
		.row.mobile {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.content {
		color: $brandBlueDarkest;
	}

	a.btn {
		background-color: $brandBlue !important;
		border-width: 0px;
		padding: 5px 40px;
	}

	video {
		width: 100%;
	}

	&.is-empty {
		div:first-child {
			text-align: left;
		}

		.image-container {
			text-align: center;
		}
	}

	&.is-empty-media {
		.row > div:last-child {
			text-align: center;
		}
	}

	@include media-breakpoint-down(md) {
		.noshow {
			display: none;
		}
	}

	&.theme-title-page,
	&.theme-title-page-reverse {
		h1 {
			color: $brandBlue;
		}

		.content {
			background-color: $brandBoxGrey;
			padding: 1em;
			color: #003057;
		}

		.button-container {
			text-align: center;

			.btn {
				margin-top: -24px;
				border-radius: 0px;
			}
		}
	}

	&.theme-mirror,
	&.theme-title-page-reverse {
		.row .col:first-child {
			order: 2;
		}

		.row .col:last-child {
			order: 1;
		}
	}
}

.theme-mirror.workplace {
	@media (max-width: 762px) {
		.button-container {
			display: none;
		}
	}
}

.page-type-home .image-text-macro {
	@include media-breakpoint-down(sm) {
		.row > div:first-child {
			display: block !important;
			min-width: 100%;
		}

		padding-bottom: 0px;

		h1 {
			margin: 0px;
		}
	}
}

.page-type-contentPage .image-text-macro {
	@include media-breakpoint-down(md) {
		.row > div:first-child {
			display: block !important;
			min-width: 100%;
			img {
				width: 100% !important;
			}
		}
	}
}

.mobileimage {
	img {
		width: 100%;
	}
}

.citypagemobile {
	display: none;
}

.course-blue-box.template {
	.city-links.home {
		@media (max-width: 762px) {
			margin-left: 10px;
		}
		.item {
			margin: 0px 1px 5px 0px;

			a {
				.cityabbr {
					width: 75px;
					height: 75px;

					p {
						padding-top: 20px;
					}
				}
			}
		}
	}
}

.city-links.items.home {
	.item {
		.cityabbr {
			@media (min-width: 414px) and (max-width: 762px) {
				height: 80px;
				width: 80px;
			}
		}
	}
}

@media (max-width: 762px) {
	.first.noshow {
		padding: 0px;
	}
	.first.blue {
		padding-top: 0px;
	}

	.row.mobile {
		padding-top: 5px;
		p {
			margin: 0px;
		}
	}
	.skills-test {
		display: none;
	}

	.rating {
		display: none;
	}

	.citypage {
		display: none;
	}

	.citypagemobile {
		display: block;
	}

	.contactus {
		display: none !important;
	}

	.accordion {
		background-color: #eee;
		color: #444;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		border: none;
		text-align: left;
		outline: none;
		font-size: 15px;
		transition: 0.4s;
	}

	.accordion.active::after {
		content: '-';
		position: absolute;
		//float: right;
		z-index: 1;
		padding-left: 80%;
		font-size: 18px;
		margin-top: -30px;
	}
	.accordion::after {
		content: '+';
		position: absolute;
		//float: right;
		z-index: 1;
		padding-left: 80%;
		font-size: 18px;
		margin-top: -30px;
	}

	.active.accordion + div {
		padding-top: 10px;
	}

	.panel {
		padding: 0 18px;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
	}
	.panel.active {
		margin-bottom: 0px;
		padding-top: 0px;
		margin-top: 0px;
		overflow: hidden;
	}

	.panel.active.courseoutline {
		max-height: 100%;
	}
}
