﻿.partial.country-selector-wrapper {
    z-index: 3;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    padding-left: 6%;
    padding-right: 6%;
    margin-top: 20px;

    @include media-breakpoint-between(xs, lg) {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;


        .extra {
            display: none;
        }
    }

    &.country-select.region a {
        width: 150px;

        @include media-breakpoint-between(xs, lg) {
            width: 90px;
        }

        padding: 5px 5px;
        margin-left: 5px;
        border: 1px solid white;

        img {
            width: 30px;
        }
    }

    ul.top {
        display: block;
        top: 0px;
        right: 2em;

        & > li {
            min-width: initial;

            & > a {
                color: white;
                min-width: 100px;
                text-align: center;
                line-height: initial;
                display: inline-block;
                padding: 5px 20px;
            }

            .dropdown-container {
                flex-direction: column;
                justify-content: flex-start;
                min-height: initial;
                background-color: white;
                box-shadow: 3px 10px 17px -1px rgba(0, 0, 0, 0.75);
                text-transform: lowercase;

                a {
                    display: block;
                    margin-bottom: 15px;
                    color: white;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }


    & > ul {
        display: flex;
        align-items: center;
        padding-left: 0;
        padding-bottom: 0;

        & > li {
            text-transform: lowercase;
            min-width: 110px;
            font-size: 18px;
            display: inline-block;


            .expander-toggle {
                display: none;
            }

            .expander-cat {
                display: none;
            }

            &.country-select {
                a {
                    width: 130px;
                    display: inline-block;
                    background-color: $brandBlue;
                    padding: 5px 30px;
                    margin-left: 5px;
                    border: 1px solid white;

                    &:hover {
                        background-color: darken($brandBlue, 4%);
                    }
                }
            }


            &.country-select.region a {
                min-width: 160px;

                @include media-breakpoint-between(xs, lg) {
                    width: 90px;
                }

                padding: 5px 5px;
                margin-left: 5px;
                border: 1px solid white;

                img {
                    width: 30px;
                }
            }

            &.country-select.region {
                height: 44px;

                a {
                    min-width: 135px;
                    width: 160px;
                    padding: 5px 3px;
                    height: 32px;

                    span {
                        padding-left: 5px;
                        margin-right: 5px;
                        float: right;
                        font-weight: 500;
                        transform: rotate(90deg);
                    }
                }

                .dropdown-container {
                    padding-right: 8px;
                    padding-left: 16px;
                    background-color: white;

                    a {
                        background-color: white;
                        margin-left: 0px;
                        padding: 0px;
                        color: black;
                        text-transform: capitalize;

                        img {
                            padding-right: 10px;
                        }
                    }
                }
            }


            & > a {
                // Title header
                color: white;
                padding: 0px 5px 5px 5px;

                &:hover {
                    text-decoration: none;
                }
            }
            // Handles the hover dropdown with popper.js
            .dropdown-container {
                text-transform: none;
                background-color: white;
                color: $brandNavy;
                min-height: 10em;
                margin-top: 15px;
                width: 100%;
                display: none;
                z-index: 999;
                justify-content: center;
                box-shadow: 1px 10px 13px -7px rgba(0, 0, 0, 0.75);

                &.nosize {
                    // pay menu
                    width: auto;
                    padding: 1em;
                    margin-top: 0;
                }

                &.country-select {
                    a {
                        border-radius: 0;
                        margin: 1em;
                        width: 10em;
                    }
                }

                &.about-us {
                    flex-direction: column;
                    align-items: center;

                    .top {
                        display: flex;
                        width: 100%;
                    }

                    .bottom .btn {
                        margin: 1em 2em;
                    }
                }

                a {
                    color: $brandNavy;
                }

                [x-arrow] {
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent #fff transparent;
                    margin-left: 18px;
                    top: -10px;
                }
            }
        }
    }


    ul li {
        padding: 0.5em 0;



        .dropdown-container {
            display: none;

            a {
                border-bottom: none;
                font-weight: 400;
            }
        }

        .country-select.region {
            height: 44px;

            a {
                min-width: 66px;
                width: 90px;
                padding: 5px 3px;
                height: 32px;

                span {
                    padding-left: 5px;
                    margin-right: 5px;
                    float: right;
                    font-weight: 500;
                    transform: rotate(90deg);
                }
            }

            .dropdown-container {
                padding-right: 8px;
                padding-left: 16px;
                background-color: white;

                a {
                    background-color: white;
                    margin-left: 0px;
                    padding: 0px;
                    width: 17px;
                    color: black;
                    text-transform: uppercase;
                }
            }
        }
    }
}
