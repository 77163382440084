﻿.nav-simple-list-partial {
    p.title {
        font-size: 20px;
        font-weight: 400;
    }

    .simple-list-body {
        .item {
            width: 255px;
            font-size: 16px;
            padding: 15px 0px 15px 20px;

            a {
                font-size: 0.9em;
                color: #003057;
            }
        }
    }
}
