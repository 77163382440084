@import "../_variables";
@import "../_macros";

.macro-online-course-details{
	@include make-default-widget-spacing();

	padding-top: 2em;
	padding-bottom: 2em;

	.course-instance-wrapper{
		display: flex;
		flex-wrap: wrap;
	}

	.course-blue-box{
		width: initial;
	}

	.course-instance{
		margin-right: 1em;
		flex-grow: 1;
		width: 300px;

		.outline-text{
			flex-grow: 1;
			background-color: white;
			margin: 2em 1em;
			padding: 1em;
		}

		.body{
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;
		}

		.center{
			text-align: center;
		}
		
		.outline-download{
			margin-bottom: 1em;
			
			.title{
				font-size: 1.3em;
			}
			img{
				margin: 1em 0em;
			}

			a{
				color: $orange;
				
			}
		}

		.btn{
			margin: 1em 1em;
			background-color: $brandBlue;
			color: white;
			border-radius: 0;
			display: block;
		}

	}

	.face-to-face{
		ul{
			list-style: none;
			padding: 0;
			margin: 0;

			li{
				display: inline-block;
				margin: 1em;

				img{
					width: 38px;
					//height: 32px;
				}
			}
		}
	}
}