﻿
.partial.comparisons-wrapper {

    .certification-comparison {
        width: 250px;

        .foot-notes {
            min-height: 50px;
            font-size: 12px;
            font-weight: 500;
            padding: 0px 10px 15px 10px;
        }

        &::before {
            text-align: center;
            transform: rotate(-90deg);
            position: absolute;
            margin-left: -115px;
            margin-top: 180px;
            min-width: 180px;
            color: white;
            padding: 10px;
            border-radius: 10px 10px 0 0;
            font-size: larger;
            background-color: var(--label-color);
            content: var(--label-text);
        }

        .discount-wrapper {
            background-color: #ff3506;
            color: white;
            position: absolute;
            padding: 20px;
            border-radius: 100%;
            text-align: center;
        }

        .details-wrapper {
            margin-top: 70px;
            border-radius: 20px;

            .price-wrapper {
                min-height: 110px;
            }
        }

        .dotted-spaced {
            background-image: linear-gradient(to right, #D1D3D4 10%, rgba(255, 255, 255, 0) 0%);
            background-position: top;
            background-size: 30px 3px;
            background-repeat: repeat-x;
            height: 20px;
            margin-top: 20px;
        }

        @include media-breakpoint-between(xs, md) {
            &.other-provider {
                display: none !important;
            }
        }
    }
}
