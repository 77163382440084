@import "../_variables";

.search-dialog {
    z-index: 999;
    position: absolute;
    background-color: $brandBlue;

    top: 0;
    left: 0;
    right: 0;

    display: none;
    flex-direction: column;
    align-items: center;

    padding: 1rem 2rem 1.5rem;

    .search-autocomplete {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        gap: 0;
        background-color: #fff;

        &:has(.show) {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        li {
            display: none;
            // display: block;

            &.show {
                display: block;
            }

            a {
                display: block;
                padding: 6px 24px;
                color: $brandBlue;
                text-decoration: none;
                font-size: 16px;
            }

            &:hover {
                background-color: $brandBlue;

                a {
                    color: #fff;
                }
            }
        }
    }

    &.show {
        display: flex;
    }
}

.search-form {
    .search-bar {
        input {
            color: #000;
            border-bottom-color: #000;

            &::placeholder {
                color: #000;
            }
        }

        .search-button {
            color: #000;
        }
    }
}

.search-bar {
    width: 100%;
    max-width: 765px;
    position: relative;

    input {
        width: 100%;
        border: none;
        color: #FFF;
        border-bottom: 2px solid #FFF;
        background-color: transparent;
        padding-bottom: 8px;
        padding-right: 3rem;
        font-size: 1.2rem;

        &::placeholder {
            color: #FFF;
        }

        &:focus-visible {
            outline: none;
        }
    }

    .search-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-70%);
        color: #FFF;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.search-filter {

    ul.search-checks {
        list-style: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;

        li {
            display: inline;

            label {
                display: flex;
                align-items: center;
                gap: 8px;
                background-color: $brandNavy;
                color: #FFF;
                font-size: .8rem;
                margin-bottom: 0;

                white-space: nowrap;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: transparent;
                transition: all .2s;
                padding: 8px 16px;
                cursor: pointer;

                .icon {
                    color: #6d6d6d;
                }
            }

            input[type="checkbox"]:checked+label {
                background-color: $brandOrange;
                color: #fff;
                transition: all .2s;

                .icon {
                    color: #fff;
                }
            }

            input[type="checkbox"] {
                position: absolute;
                opacity: 0;
            }
        }
    }


}

.search-form-wrapper {
    padding-top: 4rem;
    display: flex;
    justify-content: center;
}

// .search-bar {
//     display: flex;
//     justify-content: center;
// }