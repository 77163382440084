
.blog-section {
    article.blog-post-wrapper {
        width: 750px;

        .header .title a {
            font-size: 40px;
            color: black;
            font-weight: 500;

            @include media-breakpoint-down(sm) {
                font-size: 25px;
            }
        }

        .header h1 a {
            font-size: 40px;
            color: black;
        }

        .main-image {
            padding: 50px 0 50px 0;

            img {
                max-width: 750px;
            }
        }

        .blog-meta {
            color: #505050;
        }

        .body {
            max-width: 750px;

            img {
                max-width: 750px;
                height: auto;   
            }
        }

        @include media-breakpoint-down(sm) {
            width: 250px;

            .main-image img {
                max-width: 250px;
            }

            .body {
                max-width: 250px;

                img {
                    max-width: 250px;
                }
            }
        }
    }
}
