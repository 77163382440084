div.accordian-p {
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 5px;
	text-align: left;
	margin-bottom: 1em;

	.accordian-item.selected {
		@media (max-width: 762px) {
			p:first-child::after {
				content: '-';
				//position: absolute;
				//float: right;
				z-index: 1;
				width: 20px;
				float: right;
				font-size: 18px;
				margin-top: -27px;
				font-weight: 500;
			}
			p.long-text:first-child::after {
				content: '-';
				//position: absolute;
				//float: right;
				z-index: 1;
				width: 20px;
				float: right;
				font-size: 18px;
				margin-top: -50px;
				font-weight: 500;
			}
		}
	}

	.accordian-item {
		text-align: left;

		@media (max-width: 762px) {
			p {
				strong {
					font-weight: 400;
					display: block;
				}
			}
			p:first-child {
				width: 100%;
				strong {
					padding-right: 20px;
				}
			}
			p:first-child::after {
				content: '+';
				//position: absolute;
				//float: right;
				z-index: 1;
				width: 20px;
				float: right;
				font-size: 18px;
				margin-top: -27px;
				font-weight: 500;
			}
			p.long-text:first-child::after {
				content: '+';
				//position: absolute;
				//float: right;
				z-index: 1;
				width: 20px;
				float: right;
				font-size: 18px;
				margin-top: -50px;
				font-weight: 500;
			}
		}

		&:first-child {
			text-align: left; // override image/text macro
		}

		p:first-of-type {
			margin-bottom: 0; // fix extra spacing from P
		}

		& > * {
			display: none;
		}

		& > *:first-child {
			display: block;
			cursor: pointer;
			padding: 0.75rem 1.25rem;
			border-radius: 5px;
			background-color: white;
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		}

		&.selected {
			& > *:first-child {
				margin: 0em;
			}

			& > * {
				display: block;
				margin: 1em;
			}
		}
	}
}
