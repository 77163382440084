@import '../_variables';
@import '../_macros';

.async-partial-view.schedule {
	min-height: 500px;
}

.course-dates-macro {
	color: $brandBlueDarkest;
	padding-bottom: 1em;
	min-height: 250px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;

	//display: flex;
	flex: 1;

	@at-root body.isIE11 & {
		display: block;
	}

	.mobile-title {
		display: none;
	}

	.overlay {
		height: 250px;
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
		z-index: 2;
		backdrop-filter: blur(2px);
		margin: 0 14px 0 14px;



		.selection{
			z-index:3;
			width: 80%;
			height: 180px;	
			border-radius: 5px;
			top: 50%; 
			left: 50%;
			background-color: #f5f5f5;

			p{
				margin-top: 10px;
			}

			@include media-breakpoint-between(xs, lg) {
				.extra{
					display:none;
				}
			}

			padding-top:50px;
			padding-left:6%;
			padding-right:6%;

			&.book-now.region a {
				width: 150px;

				@include media-breakpoint-between(xs, lg) {
					width: 90px;
				}
				padding: 5px 5px;
				margin-left: 5px;
				border: 1px solid white;

				img {
					width: 30px;
				}
			}

			ul.top {
				display: block;
				//position: absolute;
				top: 0px;
				right: 2em;

				& > li {
					min-width: initial;

					& > a {
						color: white;
						min-width: 100px;
						text-align: center;
						line-height: initial;
						display: inline-block;
						padding: 5px 20px;
					}

					.dropdown-container {
						flex-direction: column;
						justify-content: flex-start;
						min-height: initial;
						background-color: white;
						box-shadow: 3px 10px 17px -1px rgba(0, 0, 0, 0.75);
						text-transform: lowercase;

						a {
							display: block;
							margin-bottom: 15px;
							color: white;

							&:last-child {
								margin-bottom: 0px;
							}
						}
					}
				}
			}

			
			& > ul {
				display: flex;
				align-items: center;

				padding-left: 0;
				padding-bottom: 0;
				margin-bottom: 5px;

				& > li {
					text-transform: lowercase;
					min-width: 110px;
					font-size: 18px;
					display: inline-block;
					

					.expander-toggle {
						display: none;
					}
					.expander-cat {
						display: none;
					}

					&.book-now {
						a {
							width: 130px;
							display: inline-block;
							background-color: $brandBlue;
							padding: 5px 30px;
							margin-left: 5px;
							border: 1px solid white;

							&:hover {
								background-color: darken($brandBlue, 4%);
							}
						}
					}


					&.book-now.region a {
						min-width: 160px;
						@include media-breakpoint-between(xs, lg) {
							width: 90px;
						}
						padding: 5px 5px;
						margin-left: 5px;
						border: 1px solid white;

						img {
							width: 30px;
						}
					}

					&.book-now.region {
						height: 44px;
						a {
							min-width: 135px;
							width: 160px;
							padding: 5px 3px;
							height: 32px;

							span {
								padding-left: 5px;
								margin-right: 5px;
								float: right;
								font-weight: 500;
								transform: rotate(90deg);
							}
						}
						.dropdown-container {
							padding-right: 8px;
							padding-left: 16px;
							background-color: white;
							a {
								background-color: white;
								margin-left: 0px;
								padding: 0px;
								color: black;
								text-transform: capitalize;
								img {
									padding-right: 10px;
								}
							}
						}
					}


					& > a {
						// Title header
						color: white;
						padding: 0px 5px 5px 5px;

						&:hover {
							text-decoration: none;
						}
					}

					// Handles the hover dropdown with popper.js
					.dropdown-container {
						text-transform: none;
						background-color: white;
						color: $brandNavy;
						min-height: 10em;
						margin-top: 15px;

						width: 100%;
						display: none;
						z-index: 999;
						justify-content: center;

						box-shadow: 1px 10px 13px -7px rgba(0, 0, 0, 0.75);

						&.nosize {
							// pay menu
							width: auto;
							padding: 1em;
							margin-top: 0;
						}

						&.book-now {
							a {
								border-radius: 0;
								margin: 1em;
								width: 10em;
							}
						}

						&.about-us {
							flex-direction: column;
							align-items: center;

							.top {
								display: flex;
								width: 100%;
							}

							.bottom .btn {
								margin: 1em 2em;
							}
						}

						a {
							color: $brandNavy;
						}

						[x-arrow] {
							position: absolute;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 10px 10px 10px;
							border-color: transparent transparent #fff transparent;
							margin-left: 18px;
							top: -10px;
						}

					}

				}

			}

			
			ul li {
				padding: 0.5em 0;



				.dropdown-container {
					display: none;
					a {
						border-bottom: none;
						font-weight: 400;
					}
				}

				.book-now.region {
					height: 44px;
					a {
						min-width: 66px;
						width: 90px;
						padding: 5px 3px;
						height: 32px;
	
						span {
							padding-left: 5px;
							margin-right: 5px;
							float: right;
							font-weight: 500;
							transform: rotate(90deg);
						}
					}
					.dropdown-container {
						padding-right: 8px;
						padding-left: 16px;
						background-color: white;
						a {
							background-color: white;
							margin-left: 0px;
							padding: 0px;
							width: 17px;
							color: black;
							text-transform: uppercase;
						}
					}
				}

				
			}

			
		}
	}

	@include media-breakpoint-down(sm) {
		.toolbar {
			display: block;

			.selected.mobile {
				display: none;
			}

			div {
				border-radius: 3px;
				width: 25%;
				float: left;
				text-align: center;
				background-color: #e5ebee;
				color: #003057;
				padding: 0.3em !important;

				h2 {
					padding-top: 5px;
				}

				margin-bottom: 3px;
			}

			div.course {
				width: 48%;
				margin: 1%;
				box-shadow: 0px 2px 3px 0px grey;

				@media only screen and (max-width: 350px) {
					width: 98%;
				}
			}
		}

		.traffic {
			top: 8px;
		}

		.color-container {
			padding: 0px !important;
			background-color: white !important;
			margin-top: 10px;
			& > .title,
			.date-list-container {
				display: none;
			}

			.dates {
				padding: 0px !important;
				margin: 0px;

				.trafficlight-mobile {
					display: block !important;
				}

				.date-mobile {
					display: block !important;
				}

				.date-row.heading {
					display: none;
				}

				.date-row {
					//border-radius: 5px;
					box-shadow: 0px 2px 3px 0px grey;
					display: block;
					&:nth-of-type(odd) {
						background-color: #f5f5f5;
					}

					&:nth-of-type(even) {
						background-color: #e5ebee;
					}

					height: 85px;
					padding-top: 0px !important;
					.date {
						display: none;
					}
					.date-mobile {
						width: 85px;
						padding-left: 7px;
						height: 69px;
						text-align: center;
						font-size: 17px;
						.day {
							font-size: 25px;
							font-weight: 500;
						}
						line-height: 25px;
						grid-column: 1 / 2 !important;
						grid-row: 1 / span 3 !important;
					}

					.title {
						font-size: 14px;
						font-weight: 500;
						height: 23px;
						width: 165px;

						@media only screen and (max-width: 350px) {
							font-size: 13px;
							width: 130px;
						}
						grid-column: 2 / 3 !important;
						grid-row: 1 / 2 !important;
					}

					.city {
						height: 23px;
						grid-column: 2 / 3 !important;
						grid-row: 2 / 3 !important;
					}

					.trafficlight-mobile {
						span {
							font-weight: 500;
						}
						height: 23px;
						grid-column: 2 / 3 !important;
						grid-row: 3 / 4 !important;
					}

					.trafficlight {
						grid-column: 3 / 4 !important;
						grid-row: 1 / span 3 !important;
						display: none;
					}

					.book {
						grid-column: 4 / 5 !important;
						grid-row: 2 /3 !important;
						top: -13px;
						width: 77px;
						height: 27px;

						a {
							border-radius: 0px;
							font-size: 16px;
						}

						.now {
							display: none;
						}
					}
				}

				.course-date-list {
					display: none;

					.mobile-title {
						display: none;
						background-color: $brandNavy;
						color: white;
						padding: 0.5em;
						margin-top: 0.5em;
						text-align: center;
						position: relative;
						cursor: pointer;

						&:after {
							content: '+';
							position: absolute;
							right: 20px;
						}
					}

					&.selected {
						.mobile-title {
							background-color: $brandOrange;

							&:after {
								content: '-';
							}
						}

						.date-list-container {
							display: block;
							//padding-top: 1em;
							//padding-bottom: 1em;
						}
					}
				}
			}
		}
	}

	.scheduled {
		background-color: $trafficLightScheduled;
	}

	.confirmed {
		background-color: $trafficLightConfirmed;
	}

	.fewSeats {
		background-color: $trafficLightFewSeats;
	}

	.soldOut {
		background-color: $trafficLightSoldOut;
	}

	.scheduled-text {
		color: darken($trafficLightScheduled, 20%);
	}

	.confirmed-text {
		color: darken($trafficLightConfirmed, 25%);
	}

	.fewSeats-text {
		color: darken($trafficLightFewSeats, 20%);
	}

	.soldOut-text {
		color: $trafficLightSoldOut;
	}

	.traffic {
		display: inline-block;
		width: 15px;
		height: 15px;
		border-radius: 100%;
		position: relative;
		top: -2px;
	}

	.toolbar {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;

		div {
			display: inline-block;
			background-color: $brandNavy;

			@include media-breakpoint-down(sm) {
				background-color: #e5ebee;
				border: solid 1px;
			}

			padding: 0.6em;
			cursor: pointer;
			color: white;
			font-size: 0.8em;

			&.selected {
				color: $brandBlueDarkest;
				background-color: $brandBoxGrey;
				@include media-breakpoint-down(sm) {
					background-color: white;
				}
				font-weight: bold;
				h2 {
					color: $brandBlueDarkest;
					@include media-breakpoint-down(sm) {
						font-weight: 500;
					}
					font-size: 1em;
				}
			}

			h2 {
				font-size: 1em;
				color: white;
				@include media-breakpoint-down(sm) {
					color: $brandBlueDarkest;
					font-weight: 500;
				}

				font-weight: 400;
			}
		}
	}

	.color-container {
		padding: 1em;
		background-color: $brandBoxGrey;
		flex: 1;

		& > .title {
			font-size: 14px;
			font-weight: bold;

			div {
				display: inline-block;
			}
		}

		.dates {
			padding: 10px 1em;

			& > div {
				display: none;
			}

			.selected {
				display: block;
			}

			.date-row {
				.trafficlight-mobile {
					display: none;
				}

				.date-mobile {
					display: none;
				}

				//@include make-row();
				margin-bottom: 10px;
				font-size: 13px;
				//display: flex;

				border-bottom: 1px solid lighten(#b7b7b7, 15%);
				padding: 0.5em 0;

				//justify-content: space-between;
				display: grid;
				grid-gap: 10px;
				grid-template-areas: 'date title city trafficlighttext trafficlight book';
				grid-template-columns: 2fr 3fr 1fr 1fr 68px 80px;
				//justify-content: center;

				&.heading {
					text-align: left;
					font-weight: 500;

					@media (min-width: 1586px) {
						grid-template-areas: 'date title city trafficlight trafficlight book';
						grid-template-columns: 2fr 3fr 1fr 1fr 68px 80px;
					}

					.book {
						text-align: left;
					}
				}

				@media (max-width: 1585.9px) {
					grid-template-areas: 'date title city trafficlight book';
					grid-template-columns: 2fr 3fr 80px 68px 80px;

					.trafficlight-text {
						display: none;
					}
				}

				@include media-breakpoint-down(sm) {
					grid-template-columns: 100px 3fr 1fr 1fr;
					grid-template-rows: 20px 17px;
					//grid-template-areas: 'date title city book trafficlight';
					&.heading .trafficlight {
						display: none;
					}
				}

				@include media-breakpoint-down(xs) {
					grid-template-columns: 85px auto;
					grid-template-rows: 20px 17px;
					grid-template-areas:
						'trafficlight date title'
						'. city book';

					&.heading .city {
						display: none;
					}

					.book a {
						width: 100%;
						font-size: 16px !important;
						padding: 3px 10px;
						text-transform: lowercase;
						font-family: Interstate;
					}

					//margin-bottom: 2em;

					margin-bottom: 10px;
				}

				.date {
					grid-area: date;
				}

				.title {
					grid-area: title;
				}

				.city {
					grid-area: city;
				}

				.book {
					grid-area: book;
					text-align: right;
					position: relative;
					top: -7px;

					a {
						display: inline-block;
						min-width: 79px;
						text-align: center;
						font-size: 11px;
						@include media-breakpoint-down(xs) {
							width: 60px;
							min-width: 60px;
							margin-right: 10px;
						}
					}
				}

				.trafficlight {
					grid-area: trafficlight;
					display: flex;
					justify-content: center;
					align-items: center;

					@media only screen and (max-width: 350px) {
						display: none;
					}
				}

				.trafficlight-text {
					grid-area: trafficlighttext;
				}
			}
		}

		.book a {
			border-radius: 20px;
			color: white;
			padding: 4px 10px;

			&.scheduled,
			&.confirmed,
			&.fewSeats {
				background-color: $brandBlue;
			}
		}
	}

	.actions {
		margin-top: 1em;
		margin-bottom: 1em;

		button {
			padding: 5px 20px;
			font-size: 12px;
			border: 0;
			outline: 0;
			color: white;
			background-color: $brandBlueDarkest;
			@media (max-width: 762px) {
				width: 49%;
			}
		}
	}

	.explain {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		font-size: 13px;
		margin-top: 2em;

		& > div {
			margin-bottom: 1em;
			min-width: 140px;
		}

		@media (max-width: 762px) {
			display: none;
		}
	}
}
